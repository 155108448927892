export const tax = [
  { id: 1, value: 0, label: '0%' },
  { id: 2, value: 0.1, label: '0.1%' },
  { id: 3, value: 0.25, label: '0.25%' },
  { id: 4, value: 1, label: '1%' },
  { id: 5, value: 1.5, label: '1.5%' },
  { id: 6, value: 3, label: '3%' },
  { id: 7, value: 5, label: '5%' },
  { id: 8, value: 6, label: '6%' },
  { id: 9, value: 7.5, label: '7.5%' },
  { id: 10, value: 12, label: '12%' },
  { id: 11, value: 18, label: '18%' },
  { id: 12, value: 28, label: '28%' },
];
