export const terms = [
  'Net 15',
  'Net 30',
  'Net 45',
  'Net 60',
  'Due end of the month',
  'Due end of next month',
  'Due on Receipt',
  'Custom',
  'Configure Setting',
];
