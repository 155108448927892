// import { fontStyle } from '@mui/system';
// import { BlobProvider } from '@react-pdf/renderer';
// import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';
// import{img} from 'assets/images/others/beta-tag.svg'
import { Tooltip,IconButton } from '@mui/material';
import Iconify from '../components/Iconify';
// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    '/assets/images/home/ic_sketch.svg',
    '/assets/images/home/ic_figma.svg',
    '/assets/images/home/ic_js.svg',
    '/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    
    subscription: 'Standard',
    monthly: 599,
    yearly:  5000,
    lists: [
      { text: 'Per Organisation/Month', isAvailable: true },
      {yearly: <span>Per Organisation/Month <br /> Billed anually</span>, isAvailable:true},

     
    ],
    labelAction: 'Sign up',
    list2: [
      { text: 'Invite 3 users', isAvailable: true },
      { text: '1 GSTINs', isAvailable: true },
      { text: 'Bill of Entry', isAvailable: true },
      { text: 'Project Invoices', isAvailable: true },
      { text: <p><Iconify icon="charm:tick" color="blue" />Project Tasks <Tooltip><IconButton><Iconify icon="ion:information-circle-outline" width="18px" height="18px" /></IconButton></Tooltip></p>, isAvailable: false },
      { text: 'Project Expenses', isAvailable: true },
      { text: 'e-invoicing', isAvailable: true },
      { text: 'Reporting tags', isAvailable: true },
      { text: 'Transaction Locking', isAvailable: true },
      { text: 'Recurring Expenses', isAvailable: true },
      { text: 'Custom View', isAvailable: true },
      { text: 'Custom Fields', isAvailable: true },
      { text: 'Timesheet and Billing', isAvailable: true },
      { text: <p><Iconify icon="charm:tick" color="blue" />Payment Gateways <Tooltip><IconButton><Iconify icon="ion:information-circle-outline" width="18px" height="18px" /></IconButton></Tooltip> </p>, isAvailable: false },
      { text: <strong>Integrations</strong>, isAvailable: false },
      { text: 'Uber', isAvailable: true },
      { text: 'Zendesk ', isAvailable: true },
      { text: 'StartupKhata People ', isAvailable: true },
      { text: 'StartupKhata Projects', isAvailable: true },
      { text: <strong>Support</strong>, isAvailable: false },
      { text: 'Email, Voice, Chat',isAvailable:false },
    ],
  },  
  {
    subscription: 'Professional',
    monthly: 799,
    yearly:   7000,
    lists: [
      { text: 'Per Organisation/Month', isAvailable: true },
      {yearly: <span>Per Organisation/Month <br /> Billed anually</span>, isAvailable:true},
    ],
    labelAction: 'Sign up',
    list3: [
      { text: 'Invite 5 users', isAvailable: true },
      { text: '2 GSTINs', isAvailable: true },
      { text: <strong>Includes everything in <span style={{ color: '#6B73FF', fontSize: '20px' }}>Standard +</span></strong>, isAvailable: true },
      { text: 'Retainer Invoices', isAvailable: true },
      { text: 'Basic Multi-currency Handling', isAvailable: true },
      { text: 'Automatic Exchange Rates', isAvailable: true },
      { text: 'Digital Signature', isAvailable: true },
      { text: 'Prject Time Tracking', isAvailable: true },
      { text: 'Project Profitability', isAvailable: true },
      { text: 'Price Lists', isAvailable: true },
      { text: 'Landed Costs', isAvailable: true },
      { text: 'Currency Adjustments', isAvailable: true },
      { text: 'Genral Templates', isAvailable: true },
      { text: 'Tasks', isAvailable: true },
      { text: 'Recurring Journals', isAvailable: true },
      { text: 'Recurring Bills', isAvailable: true },
      { text: 'Client Approvals', isAvailable: true },
      { text: 'Sales Approval', isAvailable: true },
      { text: 'Timesheet Approval', isAvailable: true },
      { text: 'Timesheet Client Approval', isAvailable: true },
      { text: <p><Iconify icon="charm:tick" color="blue" />Contextual Chat <Tooltip><IconButton><Iconify icon="ion:information-circle-outline" width="18px" height="18px" /></IconButton></Tooltip> </p>, isAvailable: false }, 
      { text: 'Purchase Approval', isAvailable: true },
      { text: 'Custom Roles', isAvailable: true },
      { text: <p><Iconify icon="charm:tick" color="blue" />StartupKhata Payroll <br /> (10 employees) <Tooltip><IconButton><Iconify icon="ion:information-circle-outline" width="18px" height="18px" /></IconButton></Tooltip> </p>, isAvailable: false }, 
      { text: <p><Iconify icon="charm:tick" color="blue" />Workflow Rules <br /> (Up to 10) <Tooltip><IconButton><Iconify icon="ion:information-circle-outline" width="18px" height="18px" /></IconButton></Tooltip> </p>, isAvailable: false },      
      { text: <strong>Support</strong>, isAvailable: false },
      { text: 'Email, Voice, Chat',isAvailable:false },
    ]
  },
  {
    subscription: 'Premium',
    monthly: 1599,
    yearly:  13000,
    lists: [
      { text: 'Per Organisation/Month', isAvailable: true },
      {yearly: <span>Per Organisation/Month <br /> Billed anually</span>, isAvailable:true},
    ],
    labelAction: 'Sign up',
    list4: [
      { text: 'Invite 10 users', isAvailable: true },
      { text: '3 GSTINs', isAvailable: true },
      { text: <strong> Includes everything in <span style={{ color: '#6B73FF', fontSize: '18px' }}> Professional +</span></strong>, isAvailable: true },
      { text: 'Vendor Portal', isAvailable: true },
      { text: 'Multi-Transaction Number Series', isAvailable: true },
      { text: 'Budgeting', isAvailable: true }, { text: 'Print Cheques', isAvailable: true },
      { text: 'Custom Domain', isAvailable: true },
      { text: 'Custom Status', isAvailable: true },
      { text: 'Custom Buttons and Links', isAvailable: true },
      { text: 'Validation Rules', isAvailable: true },
      { text: 'Related Lists', isAvailable: true },
      { text: 'WebTabs', isAvailable: true },
      { text: <p><Iconify icon="charm:tick" color="blue" />Workflow Rules <br /> (Up to 200) <Tooltip><IconButton> <Iconify icon="ion:information-circle-outline" width="18px" height="18px" /></IconButton></Tooltip></p>, isAvailable: false },      
      { text: <p><Iconify icon="charm:tick" color="blue" />StartupKhata Payroll <br /> (20 employees) <Tooltip><IconButton> <Iconify icon="ion:information-circle-outline" width="18px" height="18px" /></IconButton></Tooltip></p>, isAvailable: false }, 
      { text: 'Custom Reports', isAvailable: true },
      { text: '10 Custom Modules ', isAvailable: true },
      { text:  <img src="assets/images/others/beta-.png" alt="err" height={"20px"} mb="10px" /> , isAvailable: false },
      { text: <strong>Integrations</strong>, isAvailable: false },
      { text: 'Twilio ', isAvailable: true },
      { text: <strong>Support</strong>, isAvailable: false },
      { text: 'Email, Voice, Chat',isAvailable:false },
    ]
    
  },
  // {
  //   subscription: 'Elite',
  //   monthly:  2999,
  //   yearly:  22000,
  //   lists: [
  //     { text: 'Per Organisation/Month', isAvailable: true },
  //     {yearly:'Billed anually', isAvailable:true},

  //   ],
  //   labelAction: 'Sign up',
  //   list5: [
  //     { text: 'Invite 15 Users',isAvailable:true },
  //     { text: '3 GSTINs',isAvailable:true },
  //     { text: <strong>Includes everything in <span style={{color:'#6B73FF',fontSize:'20px'}}>Premium +</span></strong>,isAvailable:true },
  //     { text: 'Cashflow Forecasting Report',isAvailable:true },
  //     { text: 'Advanced Multi-currency Handling ',isAvailable:true },
  //     { text: <strong>Advanced Inventory Control</strong>,isAvailable:false },
  //     { text: 'i. Integrate Shopify Stores (Up to 2 stores)',isAvailable:false },
  //     { text: 'ii. Warehouse Management (Up to 5 warehouses)',isAvailable:false },
  //     { text: 'iii.Serial Number Tracking',isAvailable:false },
  //     { text: 'iv. Batch Tracking',isAvailable:false },
  //     { text: 'v. Print Shipping Label (Up to 7,500)',isAvailable:false },
  //     { text: 'vi.Shipment Tracking (Up to 7,500)',isAvailable:false },
  //     { text: <strong>Support</strong>,isAvailable:false },
  //     { text: 'Email, Voice, Chat',isAvailable:false },
  //   ]
  // },
  // {
  //   subscription: 'Ultimate',
  //   monthly: 4499,
  //   yearly: 32000,
  //   lists: [
  //     { text: 'Per Organisation/Month', isAvailable: true },
  //     {yearly:'Billed anually', isAvailable:true},
  //   ],
  //   labelAction: 'Sign up',
  //   list6:[
  //     { text: 'Invite 25 Users', isAvailable: true },
  //     { text: '3 GSTINs', isAvailable: true },
  //     { text: '25 Custom Modules'  , isAvailable: true },
  //     { text:  <img src="assets/images/others/beta-.png" alt="err" height={"20px"} padding="none"/> , isAvailable: false },
  //     { text:<strong>Includes everything in <span style={{color:'#6B73FF',fontSize:'20px'}}>Elite +</span></strong>,isAvailable:true },
  //     { text: <strong>Advanced Analytics</strong>,isAvailable:false },
  //     { text: 'i. View real-time reports and dashboards',isAvailable:false },
  //     { text: 'ii.Analyze and track key financial metrics',isAvailable:false },
  //     { text: 'iii.Serial Number Tracking',isAvailable:false },
  //     { text: 'iv.Analyze Zoho Books data along with other data sources',isAvailable:false },
  //     { text: 'v. Collaboratively create reports with colleagues',isAvailable:false },
  //     { text: 'vi.Export and print reports as excel or PDF',isAvailable:false },
  //     { text: 'vii.Embed reports in web sites/applications',isAvailable:false },
  //     { text: 'viii.Records/Rows (5 Million)',isAvailable:false },
  //     { text: <strong>Support</strong>,isAvailable:false },
  //     { text: 'Email, Voice, Chat',isAvailable:false },
  //   ]
  // },
];
