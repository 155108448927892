export const tcs = [
  { id: 1, sec: '206CE', value: 1, other: 1, label: '206CE Scrap 1%' },
  { id: 2, sec: '206CI', value: 5, other: 5, label: '206CI Tendu Leaves 5%' },
  { id: 3, sec: '206CB', value: 2.5, other: 2.5, label: '206CB Timber obtained 2.5%' },
  {
    id: 4,
    sec: '206CD',
    value: 2.5,
    other: 2.5,
    label: '206CD Any other forest produce 2.5%',
  },
  { id: 5, sec: '206CA', value: 1, other: 1, label: '206CA Alcoholic Liquor for human consumption 2.5%' },
  { id: 6, sec: '206CF', value: 2, other: 2, label: '206CF Parking Lot 2%' },
  { id: 7, sec: '206CG', value: 2, other: 2, label: '206CG Toll Plaza 2%' },
  { id: 8, sec: '206CH', value: 2, other: 2, label: '206CH Mining and quarring 2%' },
  { id: 9, sec: '206CJ', value: 1, other: 1, label: '206CJ Minerals 1%' },
  { id: 10, sec: '206CL', value: 1, other: 1, label: '206CL Sale of Motor vehicle 1%' },
  { id: 11, sec: '206CK', value: 1, other: 1, label: '206CK Bullion and Jewellery 1%' },
  { id: 12, sec: '206CM', value: 1, other: 1, label: '206CM Sale in cash of any goods 1%' },
  { id: 13, sec: '206CN', value: 1, other: 1, label: ' 206CN Providing of any services  1%' },
  {
    id: 14,
    sec: '206CQ',
    value: 5,
    other: 10,
    label: ' 206CQ LRS – Other Purposes -  [5%,10%]',
  },
  { id: 15, sec: '206CO', value: 5, other: 10, label: '206CO Tour Program Package [5%,10%]' },
  { id: 16, sec: '206CR', value: 0.1, other: 1, label: '206CR Sale of Goods [0.1%,1%]' },
  {
    id: 17,
    sec: '206CC',
    value: 2.5,
    other: 2.5,
    label: '206CC Timber obtained under any other mode 2.5%',
  },
  { id: 18, sec: '206CP', value: 0.5, other: 0.5, label: '206CP LRS – Educational Loan - Financial Institution 0.5%' },
];
