export const state = [
  { id: 1, label: '01 Jammu & Kashmir' },
  { id: 2, label: '02 Himachal Pradesh' },
  { id: 3, label: '03 Punjab' },
  { id: 4, label: '04 Chandigarh' },
  { id: 5, label: '05 Uttarakhand' },
  { id: 6, label: '06 Haryana' },
  { id: 7, label: '07 Delhi' },
  { id: 8, label: '08 Rajasthan' },
  { id: 9, label: '09 Uttar Pradesh' },
  { id: 10, label: '10 Bihar' },
  { id: 11, label: '11 Sikkim' },
  { id: 12, label: '12 Arunachal Pradesh' },
  { id: 13, label: '13 Nagaland' },
  { id: 14, label: '14 Manipur' },
  { id: 15, label: '15 Mizoram' },
  { id: 16, label: '16 Tripura' },
  { id: 17, label: '17 Meghalaya' },
  { id: 18, label: '18 Assam' },
  { id: 19, label: '19 West Bengal' },
  { id: 20, label: '20 Jharkhand' },
  { id: 21, label: '21 Odisha' },
  { id: 22, label: '22 Chattisgarh' },
  { id: 23, label: '23 Madhya Pradesh' },
  { id: 24, label: '24 Gujarat' },
  { id: 25, label: '25 Daman & Diu' },
  { id: 26, label: '26 Dadra & Nagar Haveli' },
  { id: 27, label: '27 Maharashtra' },
  { id: 28, label: '28 Old Andhra Pradesh' },
  { id: 29, label: '29 Karnataka' },
  { id: 30, label: '30 Goa' },
  { id: 31, label: '31 Lakshadweep' },
  { id: 32, label: '32 Kerala' },
  { id: 33, label: '33 Tamil Nadu' },
  { id: 34, label: '34 Pondicherry' },
  { id: 35, label: '35 Andaman & Nicobar' },
  { id: 36, label: '36 Telangana' },
  { id: 37, label: '37 New Andhra Pradesh' },
  { id: 38, label: 'Other' },
];
