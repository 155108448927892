import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function ImgMediaCard({ img, title, descprition, bg,h,w }) {
  return (
    <Card sx={{ maxWidth:{xs:'280px',sm:'400px'}, }}>
      <CardMedia
        component="img"
        alt="green iguana"
        height={h}
        width={w}
        // image="/static/images/cards/contemplative-reptile.jpg"
        image={img}
        sx={{
          borderRadius: '8px',
          background: bg,
          height:{xs:'280px',sm:'400px'},
          top:10,
          objectFit:'contain'

        }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            color: '#05406C',
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'Work Sans',
            fontSize:{xs:'16px',sm:'18px'},
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          Lizard
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            color: '#181818',
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'Work Sans',
            fontSize: {xs:'14px',sm:'16px'},
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: 'normal',
          }}
        >
          Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents
          except Antarctica
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          sx={{
            borderRadius: '4px',
            border: '1px solid #05406C',
            background: ' rgba(57, 176, 115, 0.00)',
            color: '#39B073',
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'Work Sans',
            fontSize: {xs:'12px',sm:'16px'},
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}
          size="small"
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}