export const country = [
  { id: 1, label: 'Unitied Kingdom' },
  { id: 2, label: 'Argentina' }, 
  { id: 3, label: 'Australia' }, 
  { id: 4, label: 'Bahamas' }, 
  { id: 5, label: 'Belgium' }, 
  { id: 6, label: 'Brazil' },
  { id: 7, label: 'Canada' },
  { id: 8, label: 'China' },
  { id: 9, label: 'Colombia' },
  { id: 10, label: 'cuba' },
  { id: 11, label: 'Dominican Republic' },
  { id: 12, label: 'Ecuador' },
  { id: 13, label: 'El salvador' },
  { id: 14, label: 'France' },
  { id: 15, label: 'Germany' },
  { id: 16, label: 'Guatemala' },
  { id: 17, label: 'Haiti' },
  { id: 18, label: 'Honduras' },
  { id: 19, label: 'India' },
  { id: 20, label: 'Ireland' },
  { id: 21, label: 'Israel' },
  { id: 22, label: 'Italy' },
  { id: 23, label: 'Japan' },
  { id: 24, label: 'Korea' },
  { id: 25, label: 'Mexico' },
  { id: 26, label: 'Netherlands' },
  { id: 27, label: 'Philippines' },
  { id: 28, label: 'Spain' },
  { id: 29, label: 'Sweden' },
  { id: 30, label: 'Switzerland' },
  { id: 31, label: 'Taiwan' },
  { id: 32, label: 'Venezuela' },
  { id: 33, label: 'Vietnam' },
  { id: 34, label: 'Afghanistan' },
  { id: 35, label: 'Albania' },
  { id: 36, label: 'Algeria' },
  { id: 37, label: 'American samoa' },
  { id: 38, label: 'Andorra' },
  { id: 39, label: 'Angola' },
  { id: 40, label: 'Armenia' },
  { id: 41, label: 'Austria' },
  { id: 42, label: 'Azerbaijan' },
  { id: 43, label: 'Bahrain' },
  { id: 44, label: 'Bangladesh' },
  { id: 45, label: 'Barbados' },
  { id: 46, label: 'Belarus' },
  { id: 47, label: 'Belize' },
  { id: 48, label: 'Benin' },
  { id: 49, label: 'Bermuda' },
  { id: 50, label: 'Bhutan' },
  { id: 51, label: 'Bolivia' },
  { id: 52, label: 'Bosnia And Herzegovina' },
  { id: 53, label: 'Botswana' },
  { id: 54, label: 'Bulgaria' },
  { id: 55, label: 'Burkina Faso'  },
  { id: 56, label: 'Burundi' },
  { id: 57, label: 'Cambodia' },
  { id: 58, label: 'Cameroon' },
  { id: 59, label: 'Cape Verde Islands' },
  { id: 60, label: 'Chad' },
  { id: 61, label: 'Chile' },
  { id: 62, label: 'Congo' },
  { id: 63, label: 'Costa Rica' },
  { id: 64, label: 'Croatia' },
  { id: 65, label: 'Cyprus' },
  { id: 66, label: 'Czech Republic' },
  { id: 67, label: 'Denmark' },
  { id: 68, label: 'Djibouti' },
  { id: 69, label: 'Dominica' },
  { id: 70, label: 'Egypt' },
  { id: 71, label: 'Eritrea' },
  { id: 72, label: 'Estonia' },
  { id: 73, label: 'Ethiopia' },
  { id: 74, label: 'Fiji' },
  { id: 75, label: 'Finland' },
  { id: 76, label: 'French Polynesia' },
  { id: 77, label: 'Gabon' },
  { id: 78, label: 'Gambia' },
  { id: 79, label: 'Georgia' },
  { id: 80, label: 'Ghana' },
  { id: 81, label: 'Greece' },
  { id: 82, label: 'Grenada' },
  { id: 83, label: 'Guinea' },
  { id: 84, label: 'Guyana' },
  { id: 85, label: 'Hungary' },
  { id: 86, label: 'Iceland' },
  { id: 87, label: 'Indonesia' },
  { id: 88, label: 'Iran' },
  { id: 89, label: 'Iraq' },
  { id: 90, label: 'Jamaica' },
  { id: 91, label: 'Jordan' },
  { id: 92, label: 'Kazakhstan' },
  { id: 93, label: 'Kenya' },
  { id: 94, label: 'Kuwait' },
  { id: 95, label: 'Latvia' },
  { id: 96, label: 'Lebanon' },
  { id: 97, label: 'Liberia' },
  { id: 98, label: 'Libya' },
  { id: 99, label: 'Lithuania' },
  { id: 100, label: 'LUXEMBOURG Luxembourg' },
  { id: 101, label: 'Madagascar' },
  { id: 102, label: 'Malawi' },
  { id: 103, label: 'Malaysia' },
  { id: 104, label: 'Maldives' },
  { id: 105, label: 'Mali' },
  { id: 106, label: 'Malta' },
  { id: 107, label: 'Mauritania' },
  { id: 108, label: 'Mauritius' },
  { id: 109, label: 'Monaco' },
  { id: 110, label: 'Mongolia' },
  { id: 111, label: 'Montenegro' },
  { id: 112, label: 'Morocco' },
  { id: 113, label: 'Mozambique' },
  { id: 114, label: 'Namibia' },
  { id: 115, label: 'Nepal' },
  { id: 116, label: 'New Zealand' },
  { id: 117, label: 'Nicaragua' },
  { id: 118, label: 'Niger' },
  { id: 119, label: 'Nigeria' },
  { id: 120, label: 'Norway' },
  { id: 121, label: 'Oman' },
  { id: 122, label: 'Pakistan' },
  { id: 123, label: 'Panama' },
  { id: 124, label: 'Papua New Guinea' },
  { id: 125, label: 'Paraguay' },
  { id: 126, label: 'Peru' },
  { id: 127, label: 'Poland' },
  { id: 128, label: 'Portugal' },
  { id: 129, label: 'Qatar' },
  { id: 130, label: 'Romania' },
  { id: 131, label: 'Rwanda' },
  { id: 132, label: 'Saudi Arabia' },
  { id: 133, label: 'Senegal' },
  { id: 134, label: 'Serbia' },
  { id: 135, label: 'Sierrarra Leone' },
  { id: 136, label: 'Singapore' },
  { id: 137, label: 'Slovakia' },
  { id: 138, label: 'Slovenia' },
  { id: 139, label: 'Solomon Islands' },
  { id: 140, label: 'Somalia' },
  { id: 141, label: 'South Africa' },
  { id: 142, label: 'Sri Lanka' },
  { id: 143, label: 'Sudan' },
  { id: 144, label: 'Suriname' },
  { id: 145, label: 'Swaziland' },
  { id: 146, label: 'Tajikistan' },
  { id: 147, label: 'Thailand' },
  { id: 148, label: 'Togo' },
  { id: 149, label: 'Trinidad And Tobago' },
  { id: 150, label: 'Tunisia' },
  { id: 151, label: 'Turkey' },
  { id: 152, label: 'Turkmenistan' },
  { id: 153, label: 'Tuvalu' },
  { id: 154, label: 'Uganda' },
  { id: 155, label: 'Ukraine' },
  { id: 156, label: 'United Arab Emirates' },
  { id: 157, label: 'United States' },
  { id: 158, label: 'Uruguay' },
  { id: 159, label: 'Uzbekistan' },
  { id: 160, label: 'Vanuatu' },
  { id: 161, label: 'Yemen' },
  { id: 162, label: 'Zambia' },
  { id: 163, label: 'Tanzania' },
  { id: 164, label: 'Other' },
];
