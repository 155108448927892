export const rcm = [
  {
    id: 1,
    value: '5',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 5%',
    label: 'GTA/Transport By Road RCM 13/2017 (CTR) dated 28/06/2017 5%',
  },
  {
    id: 2,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: 'Advocate 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 3,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: 'Arbitral Tribunal 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 4,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: 'Sponsorship to any body corporate 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 5,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: 'Services by the Central Government 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 6,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: 'Services by Central Government 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 7,
    value: '18',
    show: '6/2019- CT (dated) w.e.f. 01-04-2019 RCM 18%',
    label: 'Transfer of development rights or Floor 6/2019- CT (dated) w.e.f. 01-04-2019 18%',
  },
  {
    id: 8,
    value: '18',
    show: '6/2019- CT (dated) w.e.f. 01-04-2019 RCM 18%',
    label: 'Long Term Lease (30 years or more)  6/2019- CT (dated) w.e.f. 01-04-2019 18%',
  },
  {
    id: 9,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: '"Services by a Director of a company 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 10,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: 'Services by an insurance agent to any person 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 12,
    value: '18',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 18%',
    label: 'Services by a recovery agent to a banking 13/2017 (CTR) dated 28/06/2017 18%',
  },
  {
    id: 13,
    value: '12',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 12%',
    label: 'Services by an author, music composer, 13/2017 (CTR) dated 28/06/2017 12%',
  },
  {
    id: 14,
    value: '5',
    show: '13/2017 (CTR) dated 28/06/2017 RCM 5%',
    label: 'Supply of services by an author 13/2017 (CTR) dated 28/06/2017 5%',
  },
  {
    id: 15,
    value: '18',
    show: '33/2017 (CTR) 13.10.2017 RCM 18%',
    label: 'Supply of services by the members  33/2017 (CTR) 13.10.2017 18%',
  },
  {
    id: 16,
    value: '18',
    show: '15/2018 (CTR) 26.07.2018 RCM 18%',
    label: 'Services supplied by individual DSA 15/2018 (CTR) 26.07.2018 18%',
  },
  {
    id: 17,
    value: '18',
    show: '29/2018(CTR) 31.12.2018 RCM 18%',
    label: 'Services provided by business facilitator 29/2018(CTR) 31.12.2018 18%',
  },
  {
    id: 18,
    value: '18',
    show: '29/2018(CTR) 31.12.2018 RCM 18%',
    label: '"Services provided by an agent of business 29/2018(CTR) 31.12.2018 18%',
  },
  {
    id: 19,
    value: '18',
    show: '29/2018(CTR) 31.12.2018 RCM 18%',
    label: 'Security services provided to a registered 29/2018(CTR) 31.12.2018 18%',
  },
  { id: 20, value: '0', show: 'Others', label: 'Others' },
];
