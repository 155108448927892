export const sac = [
    99,
9954,
995411,
995412,
995413,
995414,
995415,
995416,
995419,
995421,
995422,
995423,
995424,
995425,
995426,
995427,
995428,
995429,
995431,
995432,
995433,
995434,
995435,
995439,
995441,
995442,
995443,
995444,
995449,
995451,
995452,
995453,
995454,
995455,
995456,
995457,
995458,
995459,
995461,
995462,
995463,
995464,
995465,
995466,
995468,
995469,
995471,
995472,
995473,
995474,
995475,
995476,
995477,
995478,
995479,
9961,
996111,
9962,
996211,
9963,
996311,
996312,
996313,
996321,
996322,
996329,
996331,
996332,
996333,
996334,
996335,
996336,
996337,
996339,
9964,
996411,
996412,
996413,
996414,
996415,
996416,
996419,
996421,
996422,
996423,
996424,
996425,
996426,
996427,
996429,
9965,
996511,
996512,
996513,
996519,
996521,
996522,
996531,
996532,
9966,
996601,
996602,
996603,
996609,
9967,
996711,
996712,
996713,
996719,
996721,
996722,
996729,
996731,
996739,
996741,
996742,
996743,
996744,
996749,
996751,
996752,
996753,
996759,
996761,
996762,
996763,
996764,
996791,
996792,
996793,
996799,
9968,
996811,
996812,
996813,
996819,
9969,
996911,
996912,
996913,
996921,
996922,
996929,
9971,
997111,
997112,
997113,
997114,
997119,
997120,
997131,
997132,
997133,
997134,
997135,
997136,
997137,
997139,
997141,
997142,
997143,
997144,
997145,
997146,
997147,
997149,
997151,
997152,
997153,
997154,
997155,
997156,
997157,
997158,
997159,
997161,
997162,
997163,
997164,
997169,
997171,
997172,
9972,
997211,
997212,
997213,
997214,
997215,
997221,
997222,
997223,
997224,
9973,
997311,
997312,
997313,
997314,
997315,
997316,
997319,
997321,
997322,
997323,
997324,
997325,
997326,
997327,
997329,
997331,
997332,
997333,
997334,
997335,
997336,
997337,
997338,
997339,
9981,
998111,
998112,
998113,
998114,
998121,
998122,
998130,
998141,
998142,
998143,
998144,
998145,
9982,
998211,
998212,
998213,
998214,
998215,
998216,
998221,
998222,
998223,
998224,
998231,
998232,
998240,
9983,
998311,
998312,
998313,
998314,
998315,
998316,
998319,
998321,
998322,
998323,
998324,
998325,
998326,
998327,
998328,
998331,
998332,
998333,
998334,
998335,
998336,
998337,
998338,
998339,
998341,
998342,
998343,
998344,
998345,
998346,
998347,
998348,
998349,
998351,
998352,
998359,
998361,
998362,
998363,
998364,
998365,
998366,
998371,
998372,
998381,
998382,
998383,
998384,
998385,
998386,
998387,
998391,
998392,
998393,
998394,
998395,
998396,
998397,
998399,
9984,
998411,
998412,
998413,
998414,
998415,
998419,
998421,
998422,
998423,
998424,
998429,
998431,
998432,
998433,
998434,
998439,
998441,
998442,
998443,
998451,
998452,
998453,
998461,
998462,
998463,
998464,
998465,
998466,
9985,
998511,
998512,
998513,
998514,
998515,
998516,
998517,
998519,
998521,
998522,
998523,
998524,
998525,
998526,
998527,
998528,
998529,
998531,
998532,
998533,
998534,
998535,
998536,
998537,
998538,
998539,
998540,
998541,
998542,
998549,
998551,
998552,
998553,
998554,
998555,
998556,
998557,
998559,
998591,
998592,
998593,
998594,
998595,
998596,
998597,
998598,
998599,
9986,
998611,
998612,
998613,
998614,
998615,
998619,
998621,
998622,
998631,
998632,
998633,
998634,
9987,
998711,
998712,
998713,
998714,
998715,
998716,
998717,
998718,
998719,
998721,
998722,
998723,
998724,
998725,
998726,
998727,
998729,
998731,
998732,
998733,
998734,
998735,
998736,
998739,
9988,
998811,
998812,
998813,
998814,
998815,
998816,
998817,
998818,
998819,
998821,
998822,
998823,
998831,
998832,
998841,
998842,
998843,
998851,
998852,
998853,
998860,
998871,
998872,
998873,
998874,
998875,
998876,
998877,
998881,
998882,
998891,
998892,
998893,
998894,
998895,
998896,
998897,
998898,
9989,
998911,
998912,
998920,
998931,
998932,
998933,
998941,
998942,
9991,
999111,
999112,
999113,
999119,
999121,
999122,
999123,
999124,
999125,
999126,
999127,
999128,
999129,
999131,
999132,
999133,
999134,
9992,
999210,
999220,
999231,
999232,
999241,
999242,
999243,
999249,
999259,
999291,
999292,
999293,
999294,
999295,
999299,
9993,
999311,
999312,
999313,
999314,
999315,
999316,
999317,
999319,
999321,
999322,
999331,
999332,
999333,
999334,
999341,
999349,
999351,
999352,
999353,
999359,
9994,
999411,
999412,
999421,
999422,
999423,
999424,
999431,
999432,
999433,
999441,
999442,
999443,
999449,
999451,
999459,
999490,
9995,
999511,
999512,
999520,
999591,
999592,
999593,
999594,
999595,
999596,
999597,
999598,
999599,
9996,
999611,
999612,
999613,
999614,
999615,
999621,
999622,
999623,
999629,
999631,
999632,
999633,
999641,
999642,
999651,
999652,
999659,
999661,
999662,
999691,
999692,
999693,
999694,
999699,
9997,
999711,
999712,
999713,
999714,
999715,
999719,
999721,
999722,
999723,
999729,
999731,
999732,
999791,
999792,
999793,
999794,
999795,
999799,
9998,
999800,
9999,
999900,
]