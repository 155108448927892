import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  // Button,
  // Avatar,
 // Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  // ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
// import { fToNow } from '../../../utils/formatTime';
// _mock_
// import { _notifications } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
// import { useSelector } from '../../../redux/store';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  // const [notifications, setNotifications] = useState(_notifications);

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  // const ledgerTrans = useSelector((state) => state.masterdata?.newPending);

 // const [show, setShow] = useState(true);

  // let count = 0;
  // const count = useRef(0);
  // useEffect(()=>{
  //   if(ledgerTrans === 'Pending'){
  //     // count.current += 1;
  //     count += 1;
  //     setShow(true);
  //   }
  // },[count, ledgerTrans])

  
  // const totalUnRead = 1;
  // setTimeout(() => {
  //   // totalUnRead += 1;
  //   setShow(true);
  // },4000);

  const [open, setOpen] = useState(null);

  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };

  const handleClose = () => {
    setOpen(null);
  };

  // const handleMarkAllAsRead = () => {
  //   setNotifications(
  //     notifications.map((notification) => ({
  //       ...notification,
  //       isUnRead: false,
  //     }))
  //   );
  // };

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} sx={{ width: 45, height: 45 }}>
        <Badge badgeContent={0} color="error">
          <Iconify icon="eva:bell-fill" width={25} height={25} />
        </Badge>
      </IconButtonAnimate>
      {/* //   <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 45, height: 45 }}>
      //   <Badge badgeContent={1} color="error">
      //     <Iconify icon="eva:bell-fill" width={25} height={25} />
      //   </Badge>
      // </IconButtonAnimate> */}


      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        {/* <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box> */}

        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have NO messages
            </Typography> */}
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )} */}
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
               No notification
          </Typography> */}

        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
             {/* sx={{ height: { xs: 340, sm: 'auto' } }} */}
            {/* {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))} */}
            {/* <Box sx={{ display: 'flex', m: 2.5 }}>
              {show &&<> <Typography variant="body2" sx={{mt:0.5}}>You have a pending transaction to approve</Typography>
              <Tooltip title="Close" sx={{ml:1}}>
                <IconButtonAnimate color="primary" onClick={() => setShow(!show)}>
                  <Iconify icon="eva:close-fill" width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
              </>
              }
            </Box> */}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  // const { avatar, title } = renderContent(notification);
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            {/* <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)} */}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
