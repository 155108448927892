import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  // purchase setting work
  purchaseSetting: {
    prefixInvoice: '',
    poNo: false,
    ebn: false,
    mot: false,
    col1: false,
    col2: false,
    col3: false,
    col1Name: '',
    col2Name: '',
    col3Name: '',
    job: false,
    disc: false,
    dis: false,
    transId: false,
    vehicleNo: false,
  },
  tab: 'purchase',
  purchase: [],
  voucher: null,
  inventory: [],
  ledger: [],
  bill: [],
  import: [],
  order:[],
  total: 0,
  purchaseEdit: null,
  billEdit: null,
  importEdit: null,
  orderEdit:null,
};

const slice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setImport(state, action) {
      state.import = action.payload;
    },
     
    setTab(state, action) {
      state.tab = action.payload;
    },
    setBill(state, action) {
      state.bill = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
    setLedger(state, action) {
      state.ledger = action.payload;
    },
    setPurchaseSetting(state, action) {
      state.purchaseSetting = action.payload;
    },
    setPurchase(state, action) {
      state.purchase = action.payload;
    },
    setVoucher(state, action) {
      state.voucher = action.payload;
    },
    setInventory(state, action) {
      state.inventory = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setPurchaseEdit(state, action) {
      state.purchaseEdit = action.payload;
    },
    setBillEdit(state, action) {
      state.billEdit = action.payload;
    },
    setImportEdit(state, action) {
      state.importEdit = action.payload;
    },
    setOrderEdit(state, action) {
      state.orderEdit = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setPurchaseSetting,
  setPurchase,
  setVoucher,
  setInventory,
  setLedger,
  setBill,
  setOrder,
  setTab,
  setImport,
  setTotal,
  setPurchaseEdit,
  setBillEdit,
  setImportEdit,
  setOrderEdit,
} = slice.actions;


export const getImport = () => async (dispatch) => {
  try {
    await axios.get('/purchase/importAll').then((response) => dispatch(setImport(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBill = () => async (dispatch) => {
  try {
    await axios.get('/purchase/billofsupplyAll').then((response) => dispatch(setBill(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getOrder = () => async (dispatch) => {
  try {
    await axios.get('/purchase/orderAll').then((response) => dispatch(setOrder(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLedger = () => async (dispatch) => {
  try {
    await axios.get('/purchase/ledger').then((response) => dispatch(setLedger(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getSetting = () => async (dispatch) => {
  try {
    await axios.get('/purchase/settings').then((response) => dispatch(setPurchaseSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getVoucher = (no) => async (dispatch) => {
  try {
    await axios.get(`/purchase/voucherNumber/${no}`).then((response) => dispatch(setVoucher(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInventory = () => async (dispatch) => {
  try {
    await axios.get('/purchase/inventory').then((response) => dispatch(setInventory(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPurchase = () => async (dispatch) => {
  try {
    await axios.get('/purchase/purchaseAll').then((response) => dispatch(setPurchase(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getPurchaseEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/purchase/purchase?ID=${id}`).then((response) => dispatch(setPurchaseEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBillEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/purchase/billofsupply?ID=${id}`).then((response) => dispatch(setBillEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getImportEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/purchase/import?ID=${id}`).then((response) => dispatch(setImportEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

  export const getOrderEdit = (id) => async (dispatch) => {
    try {
      await axios.get(`/purchase/order?ID=${id}`).then((response) => dispatch(setOrderEdit(response.data)));
    } catch (error) {
      return console.error(error.message);
    }
    return true;
};
