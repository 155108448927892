import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Link, Container, Typography, Card } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// import Logo from '../../components/Logo';

// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',

    //  color:'white'
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  // padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// const SectionStyle = styled(Box)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const Background = styled('div')(() => ({
  backgroundImage: `url(/assets/images/auth/mam12.webp)`,
  backgroundColor: 'rgb(1,1,1)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  // const mdUp = useResponsive('up', 'md');

  return (
    <Background>
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            {/* <Logo /> */}
            <Link component={RouterLink} to="/">
              <img
                src="/assets/images/auth/start2.webp"
                alt="err"
                width="180px"
                height="80px"
                backgroundColor="#F9EFE9"
              />
            </Link>
            {smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                Don’t have an account ? {''}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Register
                </Link>
              </Typography>
            )}
          </HeaderStyle>

          {/* {mdUp && (
          <SectionStyle style={{ background: mam }}>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image alt="login" />
          </SectionStyle>
        )} */}

          <Container maxWidth="sm" sx={{ mr: 130, mt: 10 }}>
            <ContentStyle>
              <Card
                sx={{
                  borderRadius: '12px',
                  background: '#FFF',
                  // boxShadow: '0px 4px 5.2px 6px #07395E',
                  px: 5,
                  py: 5,
                  // my: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  // transform: 'scale(0.9)',
                  width: '450px',
                  // height: '680px',
                  flexShrink: 0,
                  // gap:2,
                }}
              >
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" gutterBottom>
                      Sign in to Startup Khata
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
                  </Box>
                </Stack>
                <LoginForm />

                {!smUp && (
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Don’t have an account?{' '}
                    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                      Get started
                    </Link>
                  </Typography>
                )}
              </Card>
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </Background>
  );
}
