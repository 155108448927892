import PropTypes from 'prop-types';
import { useState,useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { getIndustriesSetting } from '../../../redux/slices/organisations';
import { useDispatch, useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustriesSetting());
  }, [dispatch]);

  const salesSetting = useSelector((state) => state.organisation?.industriesSetting);

  const [nav, setNav] = useState(navConfig);

  useEffect(() => {
    const nav1 = navConfig[0].items.filter((e) => e.title !== 'transport' && e.title !== 'Courier / Cargo');
    setNav([{ subheader: 'general', items: nav1 }]);
  }, []);

  useEffect(() => {
    if (salesSetting) {
      const div = salesSetting.sectionsEnabled;
      let div2 = '';
      if (div?.length === 0) {
        const nav1 = navConfig[0].items.filter((e) => e.title !== 'transport' && e.title !== 'Courier / Cargo');
        setNav([{ subheader: 'general', items: nav1 }]);
      }

      if (div?.length !== 0 && div?.length <= 16) {
        div2 = div?.split(',');
        if (div2) {
          if (div2[0] === 'transport') {
            const nav1 = navConfig[0].items.filter((e) => e.title !== 'Courier / Cargo');
            setNav([{ subheader: 'general', items: nav1 }]);
          } else {
            const nav1 = navConfig[0].items.filter((e) => e.title !== 'transport');
            setNav([{ subheader: 'general', items: nav1 }]);
          }

          if (div2[0] === 'courier') {
            const nav1 = navConfig[0].items.filter((e) => e.title !== 'transport');
            setNav([{ subheader: 'general', items: nav1 }]);
          } else {
            const nav1 = navConfig[0].items.filter((e) => e.title !== 'Courier / Cargo');
            setNav([{ subheader: 'general', items: nav1 }]);
          }
        }
      } else if (div?.length !== 0 && div?.length === 17) {
        setNav(navConfig);
      }
    }
  }, [salesSetting]);

  return (
    <Box {...other}>
      {nav.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
