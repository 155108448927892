import {  Outlet } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
// import { Box, Stack } from '@mui/material';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout() {
// const { pathname } = useLocation();

  // const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      <MainFooter />
      {/* {!isHome ? (
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>
            <img src="/assets/images/auth/start.png" alt="err" width="150px" height="70px"  style={{margin:'auto'}}/>

            <Typography variant="caption" component="p">
              © All rights reserveds
              <br /> made by &nbsp;
              <Link href="https://startupkhata.com/">startupkhata.com</Link>
            </Typography>
          </Container>
        </Box>
      )} */}
    </Stack>
  );
}
