export const genrateTcs = [
  { id: 13, sec: '206CN', value: 1, other: 1, label: ' 206CN Providing of any services  1%' },
  {
    id: 14,
    sec: '206CQ',
    value: 5,
    other: 10,
    label: ' 206CQ LRS – Other Purposes -  5%',
  },

  { id: 16, sec: '206CR', value: 0.1, other: 1, label: '206CR Sale of Goods 0.1%' },
];
