export const organization = [
    { id: '1', label: 'Proprietorship' },
    { id: '2', label: 'Partnership' },
    { id: '3', label: 'Limited Liability Partnership' },
    { id: '4', label: 'Private Limited Companies' },
    { id: '5', label: 'Public Limited Companies' },
    { id: '6', label: 'One - Person Companies' },
    { id: '7', label: 'Hindu Undivided Family(HUF)' },
    { id: '8', label: 'Local Authority' },
    { id: '9', label: 'Foreign Company' },
    { id: '10', label: 'Society / Club / AOP / NGO' },
    { id: '11', label: 'Statuary Body' },
    { id: '12', label: 'Others' },
];
