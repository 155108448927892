// export const cr = [
//   { id: 1, label: '₹' },
//   { id: 2, label: '$' },
//   { id: 3, label: 'ƒ' },
//   { id: 4, label: 'Br' },
//   { id: 5, label: '₼' },
//   { id: 6, label: '$b' },
//   { id: 7, label: 'KM' },
//   { id: 8, label: 'P' },
//   { id: 9, label: 'лв' },
//   { id: 10, label: 'R$' },
//   { id: 11, label: '៛' },
//   { id: 12, label: '¥' },
//   { id: 13, label: '₡' },
//   { id: 14, label: 'kn' },
//   { id: 15, label: '₱' },
//   { id: 16, label: 'Kč' },
//   { id: 17, label: 'kr' },
//   { id: 18, label: '£' },
//   { id: 19, label: '€' },
//   { id: 20, label: '¢' },
//   { id: 21, label: 'Q' },
//   { id: 23, label: 'L' },
//   { id: 24, label: 'Ft' },
//   { id: 25, label: 'kr' },
//   { id: 26, label: '؋' },
//   { id: 27, label: 'Rp' },
//   { id: 28, label: '₪' },
//   { id: 29, label: 'J$' },
//   { id: 30, label: '₭' },
//   { id: 31, label: '₩' },
//   { id: 32, label: 'RM' },
//   { id: 33, label: '₨' },
//   { id: 34, label: '₮' },
//   { id: 35, label: 'د.إ' },
//   { id: 36, label: 'MT' },
//   { id: 37, label: 'C$' },
//   { id: 38, label: '₦' },
//   { id: 39, label: 'kr' },
//   { id: 41, label: 'B/.' },
//   { id: 42, label: 'Gs' },
//   { id: 43, label: 'S/.' },
//   { id: 44, label: 'zł' },
//   { id: 45, label: 'lei' },
//   { id: 46, label: '₽' },
//   { id: 47, label: '﷼' },
//   { id: 48, label: 'S' },
//   { id: 49, label: 'R' },
//   { id: 50, label: '₴' },
//   { id: 51, label: '$U' },
//   { id: 52, label: 'Bs' },
//   { id: 53, label: '₫' },
//   { id: 54, label: '﷼' },
//   { id: 55, label: 'Z$' },
// ];

export const cr=[
  'ALL',
  'DZD',
  'AOA',
  'ARS',
  'AMD',
  'AUD',
  'EUR',
  'AZN',
  'BHD',
  'BBD',
  'BYN',
  'BMD',
  'BOB',
  'BAM',
  'BWP',
  'BRL',
  'BGN',
  'CVE',
  'KHR',
  'XAF',
  'CAD',
  'KYD',
  'CLP',
  'CNY',
  'COP',
  'CDF',
  'CRC',
  'HRK',
  'CZK',
  'DKK',
  'DOP',
  'USD',
  'EGP',
  'ETB',
  'FJD',
  'GEL',
  'GHS',
  'GIP',
  'GTQ',
  'GBP',
  'GYD',
  'HNL',
  'HKD',
  'HUF',
  'ISK',
  'INR',
  'IDR',
  'IQD',
  'IMP',
  'ILS',
  'XOF',
  'JMD',
  'JPY',
  'JOD',
  'KZT',
  'KES',
  'KRW',
  'KWD',
  'KGS',
  'LAK',
  'LBP',
  'LYD',
  'CHF',
  'MOP',
  'MGA',
  'MWK',
  'MYR',
  'MVR',
  'MRU',
  'MUR',
  'MXN',
  'MDL',
  'MNT',
  'MAD',
  'MZN',
  'MMK',
  'NAD',
  'NZD',
  'NIO',
  'NGN',
  'MKD',
  'NOK',
  'OMR',
  'PKR',
  'PAB',
  'PGK',
  'PYG',
  'PEN',
  'PHP',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'RWF',
  'XCD',
  'SAR',
  'RSD',
  'SGD',
  'ZAR',
  'LKR',
  'SZL',
  'SEK',
  'TWD',
  'TJS',
  'TZS',
  'THB',
  'TTD',
  'TND',
  'TRY',
  'TMT',
  'UGX',
  'UAH',
  'AED',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'ZMW',
  'ZWD',
];
