import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { state, country } from '../../_mock';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  bank: null,
  basic: null,
  address: null,
  tax: null,
  remark: [],
  branch: null,
  taxSetting: null,
  whatsupSetting: null,
  emailSetting: null,
  logo: null,
  hideContactNo: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfileSetting(state, action) {
      state.hideContactNo = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Tax
    setTax(state, action) {
      const tax = action.payload;
      state.tax = tax;
    },

    // GET Remark
    setRemark(state, action) {
      const remark = action.payload;
      state.remark = remark;
    },

    // GET Remark Edit
    setRemarkEdit(state, action) {
      const remarkEdit = action.payload;
      state.remarkEdit = remarkEdit;
    },

    // GET Bank
    setBank(state, action) {
      const bank = action.payload;
      state.bank = bank;
    },

    // GET Logo Signature
    setLogoSignature(state, action) {
      const logoSign = action.payload;
      state.logoSign = logoSign;
    },

    // GET Basic
    setBasic(state, action) {
      const basic = action.payload;
      state.basic = basic;
    },

    // GET Address
    setAddress(state, action) {
      const address = action.payload;
      state.address = address;
    },

    // GET Branch
    setBranch(state, action) {
      const branch = action.payload;
      state.branch = branch;
    },

    // GET Tax Setting
    setTaxSetting(state, action) {
      const taxSetting = action.payload;
      state.taxSetting = taxSetting;
    },

    // GET Whatsup Setting
    setWhatsupSetting(state, action) {
      const whatsupSetting = action.payload;
      state.whatsupSetting = whatsupSetting;
    },

    // GET Email Setting
    setEmailSetting(state, action) {
      const emailSetting = action.payload;
      state.emailSetting = emailSetting;
    },

    setUserLogo(state, action) {
      const logo = action.payload;
      state.logo = logo;
    },

    // setImgs(state,action){
    //   state.imgs = action.payload;
    // }
  },
});

// Reducer
export default slice.reducer;

export const {
  setProfileSetting,
  setAddress,
  setBasic,
  setTax,
  setBank,
  setRemark,
  setRemarkEdit,
  setContactPersonfilter,
  setLogoSignature,
  setBranch,
  setTaxSetting,
  setWhatsupSetting,
  setEmailSetting,
  setUserLogo,
} = slice.actions;

// ----------------------------------------------------------------------

export const getProfileSetting = () => async (dispatch) => {
  try {
    await axios.get('/profileSetting').then((response) => dispatch(setProfileSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBasic = () => async (dispatch) => {
  try {
    await axios.get('/basic').then((response) => dispatch(setBasic(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

// ----------------------------------------------------------------------

export const getAddress = () => async (dispatch) => {
  try {
    await axios.get('/address').then((response) => dispatch(setAddress(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTax = () => async (dispatch) => {
  try {
    await axios.get('/tax').then((response) => dispatch(setTax(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getBank = () => async (dispatch) => {
  try {
    await axios.get('/bank').then((response) => dispatch(setBank(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getLogoSignature = () => async (dispatch) => {
  try {
    await axios.get('/logoSignature').then((response) => dispatch(setLogoSignature(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getRemark = () => async (dispatch) => {
  try {
    await axios.get('/remarksall').then((response) => dispatch(setRemark(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getRemarkEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/remarks?id=${id}`).then((response) => dispatch(setRemarkEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getState = (id) => {
  let stateName;
  state.every((option) => {
    if (option.id === id) {
      stateName = option.label;
    }
    return true;
  });
  return stateName;
};

export const getCountry = (id) => {
  let countryName;
  country.every((option) => {
    if (option.id === id) {
      countryName = option.label;
    }
    return true;
  });
  return countryName;
};

export const getDate = (date) => {
  date = JSON.stringify(date);
  date = date?.slice(1, 11);
  return date;
};

export const getBranch = () => async (dispatch) => {
  try {
    await axios.get('/branch').then((response) => dispatch(setBranch(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getTaxSetting = () => async (dispatch) => {
  try {
    await axios.get('/taxSettings').then((response) => dispatch(setTaxSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getWhatsupSetting = () => async (dispatch) => {
  try {
    await axios.get('/whatsupSettings').then((response) => dispatch(setWhatsupSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getEmailSetting = () => async (dispatch) => {
  try {
    await axios.get('/emailSettings').then((response) => dispatch(setEmailSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
