import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  recentSales: [],
  profit_bar: [],
  chart_data1: [],
  chart_data2: [],
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setRecentsales(state, action) {
      state.recentSales = action.payload;
    },
    setSalesOverview(state, action) {
      state.profit_bar = action.payload;
    },
    setRecentexpense(state, action) {
      state.recent_expense = action.payload;
    },
    setChartData1(state, action) {
      state.chart_data1 = action.payload;
    },
    setChartData2(state, action) {
      state.chart_data2 = action.payload;
    },
  },
});

export default slice.reducer;

export const { setRecentsales, setSalesOverview, setRecentexpense, setChartData1, setChartData2 } = slice.actions;

export const getChartData = (all) => async (dispatch) => {
  try {
    axios.get(`/dashboard/chart-data1/${all}`).then((response) => dispatch(setChartData1(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getChartData2 = (day) => async (dispatch) => {
  try {
    axios.get(`/dashboard/chart-data2/${day}`).then((response) => dispatch(setChartData2(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getRecentsales = () => async (dispatch) => {
  try {
    axios.get('/dashboard/recentSale').then((response) => dispatch(setRecentsales(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
export const getSalesOverview = () => async (dispatch) => {
  try {
    axios.get('/dashboard/profit_bar').then((response) => dispatch(setSalesOverview(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getRecentexpense = () => async (dispatch) => {
  try {
    axios.get('/dashboard/recent_expense').then((response) => dispatch(setRecentexpense(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
