import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  newBranch: [],
};

const slice = createSlice({
  name: 'branches',
  initialState,
  reducers: {

    setNewBranch(state, action) {
      const newBranch = action.payload;
      state.newBranch = newBranch;
    },

  },
});

export default slice.reducer;

export const { setNewBranch } = slice.actions;


export const getBranch = () => async (dispatch) => {
  try {
    await axios.get('/branchAll').then((response) => dispatch(setNewBranch(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
