export const tds = [
  { id: 1, sec: '192', value: 5, other: 0, label: '192 Salaries - [5/0]' },
  { id: 2, sec: '192A', value: 10, other: 0, label: '192A Premature Withdrawal From [10%]' },
  { id: 3, sec: '193', value: 10, other: 10, label: '193 Interest On Securities-[10%]' },
  { id: 4, sec: '194', value: 10, other: 10, label: '194 Dividends - [10%]' },
  { id: 5, sec: '194A', value: 10, other: 10, label: '194A Interest(Banks) - [10%]' },
  { id: 6, sec: '194A', value: 10, other: 10, label: '194A Interest(Others)- [10%]' },
  { id: 7, sec: '194A', value: 10, other: 0, label: '194A Senior Citizen - [10/0%]' },
  { id: 8, sec: '194B', value: 30, other: 30, label: '194B Winning From Lotteries [30%]' },
  { id: 9, sec: '194BB', value: 30, other: 30, label: '194BB Winning From Horse Race [30%]' },
  { id: 10, sec: '194C', value: 1, other: 1, label: '194C Contractor-Single Trans [1%]' },
  { id: 11, sec: '194C', value: 1, other: 1, label: '194C Contractor-During the F [1%]' },
  { id: 103, sec: '194C', value: 2, other: 2, label: '194C Contractor-Single Trans [2%]' },
  { id: 113, sec: '194C', value: 2, other: 2, label: '194C Contractor-During the F [2%]' },
  { id: 12, sec: '194C', value: 0, other: 0, label: '194C Transporter (44AE) Declar [0%]' },
  { id: 13, sec: '194D', value: 5, other: 10, label: '194D Insurance Commission [5/10%]' },
  { id: 14, sec: '194DA', value: 5, other: 5, label: '194DA Life Insurance Policy [5%]' },
  { id: 15, sec: '194E', value: 20, other: 20, label: '194E Non-Resident Sportsmen [20/20%]' },
  { id: 16, sec: '194EE', value: 10, other: 10, label: '194EE NSS [10%]' },
  { id: 17, sec: '194F', value: 20, other: 20, label: '194F Repurchase Units By MFs [20%]' },
  { id: 18, sec: '194G', value: 5, other: 5, label: '194G Commission-Lottery [5%]' },
  { id: 19, sec: '194H', value: 5, other: 5, label: '194H Commission/Brokerage [5%]' },
  { id: 20, sec: '194I(a)', value: 1, other: 1, label: '194I(a) Rent For Plant & Machinery [1%]' },
  { id: 21, sec: '194I(b)', value: 10, other: 10, label: '194I(b) Rent For Land,Building & Furniture [10%]' },
  {
    id: 22,
    sec: '194IA',
    value: 1,
    other: 1,
    label: '194IA Transfer Of Certain Immovable Property [1%]',
  },
  { id: 23, sec: '194IB', value: 5, other: 0, label: '194IB Rent By Individual/HUF [5/0%]' },
  {
    id: 24,
    sec: '194IC',
    value: 10,
    other: 10,
    label: '194IC Payment Under speci?ed agreement applicable [10%]',
  },
  {
    id: 25,
    sec: '194J(a)',
    value: 2,
    other: 2,
    label: '194J(a) Fees-tech srvcs,Call Centre,Royalty for Sale etc [2%]',
  },
  { id: 26, sec: '194J(b)', value: 10, other: 10, label: '194J(b) Fee For Professional Services Or Royalty etc [10%]' },
  { id: 27, sec: '194K', value: 10, other: 10, label: '194K Payment Of Dividend By Mutual Funds [10%]' },
  {
    id: 28,
    sec: '194LA',
    value: 10,
    other: 10,
    label: '194LA Compensation on Transfer Of certain Immovable [10%]',
  },
  {
    id: 29,
    sec: '194LA',
    value: 0,
    other: 0,
    label: '194LA Immovable Property [0%]',
  },
  {
    id: 30,
    sec: '194LB',
    value: 5,
    other: 5,
    label: '194LB Income By Way Of Interest From Infrastructure [5%]',
  },
  {
    id: 31,
    sec: '194LBA',
    value: 10,
    other: 10,
    label: '194LBA Certain Income From Units Of A Business Trust [10%]',
  },
  {
    id: 32,
    sec: '194LBA',
    value: 5,
    other: 5,
    label: '194LBA Certain Income From Units Of A Business [5%]',
  },
  { id: 33, sec: '194LBB', value: 10, other: 0, label: '194LBB Income In Respect Of Units [10%]' },
  { id: 34, sec: '194LBC', value: 25, other: 0, label: '194LBC Income In Respect Of Investment [25%]' },
  {
    id: 35,
    sec: '194LC',
    value: 5,
    other: 0,
    label: '194LC Income By Way Of Interest By An Indian [[5/0%]',
  },
  { id: 36, sec: '194LD', value: 5, other: 0, label: '194LD Interest On Certain Bonds And Govt. Securities [5/0%]' },
  { id: 37, sec: '194M', value: 5, other: 0, label: '194M Payment To Commission , Brokerage Etc [5/0%]' },
  {
    id: 38,
    sec: '194N',
    value: 2,
    other: 0,
    label: '194N Cash Withdrawal Inexcess Of 1 Crore [2/0%]',
  },
  { id: 39, sec: '194O', value: 1, other: 0, label: '194O TDS On E-Commerce Participants [1/0%]' },
  { id: 40, sec: '194P', value: 0, other: 0, label: '194P TDS In Case Of Speci?ed Senior Citizen [0%]' },
  {
    id: 41,
    sec: '194Q',
    value: 0.1,
    other: 0,
    label: '194Q TDS On Purchase Of Goods Exceeding Rs. 50 Lakhs [0.1/0%]',
  },
  { id: 42, sec: '195', value: 0, other: 0, label: '195 Payment To non-Residents [0%]' },
  { id: 43, sec: '194E', value: 20, other: 20, label: '194E Non-Resident Sportsman Or Sports Assn [20%]' },
  { id: 44, sec: '196A', value: 20, other: 20, label: '196A Foreign Company Being Unit Holder In Mutual Fund [20%]' },
  { id: 45, sec: '196B', value: 10, other: 10, label: '196B Units held by Oﬀ-shore Fund [10%]' },
  { id: 46, sec: '196C', value: 10, other: 10, label: '196C Income from Foreign Currency Bonds [10%]' },
  { id: 47, sec: '196D', value: 20, other: 20, label: '196D Income of FII on Securities [20%]' },
  { id: 48, sec: '194LB', value: 5, other: 5, label: '194LB Interest from infrastr. debt fund payable [5%]' },
  { id: 49, sec: '194LC', value: 5, other: 5, label: '194LC Interest from speci�ed company payable [5%]' },
  { id: 50, sec: '194LD', value: 5, other: 5, label: '194LD Interest on certain bonds and govt securities [5%]' },
  { id: 51, sec: '194LBA', value: 5, other: 5, label: '194LBA Certain income from units of a business trust [5%]' },
  { id: 52, sec: '194LBB', value: 30, other: 40, label: '194LBB Income on units of Investment Fund [30%]' },
  { id: 53, sec: '194LBC', value: 30, other: 40, label: '194LBC Income in respect of investment [30%]' },
  { id: 54, sec: '194N', value: 2, other: 2, label: '194N Payment of certain amounts in cash [2%]' },
  {
    id: 55,
    sec: '194LBA(a)',
    value: 10,
    other: 10,
    label: '194LBA(a) Income referred to in section [10%]',
  },
  {
    id: 56,
    sec: '194LBA(b)',
    value: 5,
    other: 5,
    label: '194LBA(b) Income referred to in section [5%]',
  },
  { id: 57, sec: '194NF', value: 1, other: 1, label: '194NF Payment of certain amounts [1%]' },
  {
    id: 58,
    sec: '194LBA(c)',
    value: 30,
    other: 30,
    label: '194LBA(c) Income referred to in section [30%]',
  },
];
