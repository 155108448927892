import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  // job setting work
  jobData: [],
  jobEdit: [],
  jobSetting: {},
  invoiceNumber: '',
};

const slice = createSlice({
  name: 'jobproject',
  initialState,
  reducers: {
    setjobProjectEdit(state, action) {
      state.jobEdit = action.payload;
    },
    setIsloading(state, action) {
      state.isLoading = action.payload;
    },
    setJobSetting(state, action) {
      state.jobSetting = action.payload;
    },
    setJobData(state, action) {
      state.jobData = action.payload;
    },
    setPdf(state, action) {
      state.pdf = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setinvoiceNumber(state, action) {
      state.invoiceNumber = action.payload;
    },
  },
});

export const getjobProject = () => async (dispatch) => {
  try {
    await axios.get(`/jobs/jobAll`).then((response) => dispatch(setJobData(response.data)));
    dispatch(setIsloading(false));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getjobProjectEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/jobs/job?ID=${id}`).then((response) => dispatch(setjobProjectEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getjobSetting = () => async (dispatch) => {
  try {
    await axios.get('/jobs/jobsettings').then((response) => dispatch(setJobSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getinvoiceNumber = () => async (dispatch) => {
  try {
    await axios.get('/jobs/invoiceNumber').then((response) => dispatch(setinvoiceNumber(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export default slice.reducer;

export const { setJobSetting, setJobData, setIsloading, setTab, setjobProjectEdit, setPdf, setinvoiceNumber } =
  slice.actions;
