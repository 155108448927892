import { useNavigate , useLocation} from 'react-router';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { styled, useTheme } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Typography, Card, Button, Tabs, Tab, Tooltip, IconButton, Stack, Alert,Container,AppBar,Toolbar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Page from '../components/Page';
// import Logo from '../components/Logo';
import { RHFTextField, FormProvider } from '../components/hook-form';
import axios from '../utils/axios';
import navConfig from './Offerhomebtn';
import Homecolor from './Offerhomecolor';
import Phonesize from './Offerphonesize';
import Iconify from '../components/Iconify';
import useOffSetTop from '../hooks/useOffSetTop';
import useIsMountedRef from '../hooks/useIsMountedRef';
import useResponsive from '../hooks/useResponsive';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH } from '../routes/paths';
import PoPMessageForm from './PoPMessageForm';
import { HEADER } from '../config';

function getCurrentYear() {
  return new Date().getFullYear();
}

export default function Offer() {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const isDesktop = useResponsive('up', 'lg');

  const { enqueueSnackbar } = useSnackbar();
  const offerSchema = Yup.object().shape({
    customerName: Yup.string().required('This is required field'),
    quoteMobile: Yup.string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(10, 'Too Short!')
      .max(10, 'Too Long!'),
    quoteEmail: Yup.string().email('Email must be a valid email address'),
    quoteMessage: Yup.string().required('This is required field'),
  });

  const defaultValues = {
    customerName: '',
    quoteEmail: '',
    quoteMobile: '',
    quoteMessage: '',
  };

  const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
      height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
  }));

  const ToolbarShadowStyle = styled('div')(({ theme }) => ({
    left: 0,
  }));

  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const methods = useForm({
    resolver: yupResolver(offerSchema),
    defaultValues,
  });
  const isMountedRef = useIsMountedRef();

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = async (data) => {
    try {
      const { quoteMobile, quoteEmail } = data;
      if (quoteEmail === '' && quoteMobile === null) {
        setErrorMessage('Email or Mobile both can not be empty');
        throw new Error('Email or Mobile both can not be empty');
      } else {
        await axios.post('/postQuote', data);
        enqueueSnackbar('create success!');
      }
      reset();
    } catch (error) {
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };
  const [show, setshow] = useState(false);

  // const [read, setread] = useState(false);

  const [see, setsee] = useState(false);
  const abc = true;
  const [state, setState] = useState(0);

  const handleClick = async (subscriptionType, subscription) => {
    if (isAuthenticated) {
      let localSubscription = parseInt(subscription, 10);
      if (subscriptionType === 1) {
        localSubscription += 1;
      }
      if (window.location.origin !== 'https://www.startupkhata.com') {
        localSubscription = 11;
      }
      const { data } = await axios.post('subscriptions/get-token', {
        subscription: localSubscription,
      });
      if (data.encryption) {
        window.location.href = data.encryption;
      }
    } else {
      const pack = {
        subscriptionType,
        subscription,
      };
      const packs = JSON.stringify(pack);
      localStorage.setItem('package', packs);
      navigate(PATH_AUTH.register);
    }
  };

  return (
    
    
    <Page sx={{bgcolor:'#f0f4f8'}}>
      <PoPMessageForm />
      {isDesktop ? (
        <Box sx={{}}>
          {isDesktop && <LazyLoadImage src="assets/images/offer/accounting-banner.png" alt="err" style={{ width: '100%', height: '50%' }} />}
          <Box sx={{ display: 'flex', width: '80%', ml: '13%', flexWrap: 'wrap' }}>
          <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent', color:'white' }}>
          <ToolbarStyle sx={{}} >
            <Container
            >

              <Homecolor isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
             
            </Container>
          </ToolbarStyle>

          {isOffset && <ToolbarShadowStyle />}
        </AppBar>
            {/* <Logo sx={{ width: '170px' }} /> */}
            <Box sx={{position:'absolute', top:'2%', left:'10%', width: '150px', height: '80px',}}>
      <img src='/assets/images/auth/start2.webp' alt='err' width="80%" height="80%"/>
    </Box>

            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                mr: 6,
                textDecoration: 'none',
                fontSize: '18px',
                xs: '500px',
                // display: 'flex',
                // alignItems:'center',
                // justifyContent: 'space-between',
                ml: '52%',
              }}
            >
              <a href="tel:011404-52-748" style={{ textDecoration: 'none', color: 'whitesmoke' }}>
                Toll free : <br /> (011) 404-52-748
              </a>
            </Typography >
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                mr: 6,
                textDecoration: 'none',
                fontSize: '18px',
                // display: 'flex',
                // alignItems:'center',
                // justifyContent: 'space-between',
                xs: '500px',
                ml: '65%'
              }}>
              <a href="tel:+918826081545" style={{ textDecoration: 'none', color: 'whitesmoke' }}>
                Phone No. : <br /> (+91) 8826081545
              </a>
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={{ padding: '', height: '19rem', width: '100%' }}>
          <LazyLoadImage src="assets/images/offer/accounting-banner.png" alt="err" style={{ height: '500px' ,width:'100%'}} />
          <Box sx={{ width: '100%', ml: '25%' }}>
          <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent',display:'flex',alignItems:'flex-end',justifyContent:'flex-end' }}>
          <ToolbarStyle sx={{}} >
            <Container
            >

              <Phonesize isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
             
            </Container>
          </ToolbarStyle>

          {isOffset && <ToolbarShadowStyle />}
        </AppBar>
            {/* <Logo sx={{ width: '100px', height: '50px', mt: '5%' }} /> */}
            <br />
            <Box sx={{position:'absolute', top:'3%', left:'5%', width: '150px', height: '80px',}}>
      <img src='/assets/images/auth/start2.webp' alt='err' width="80%" height="80%"/>
    </Box>
            <a href="tel:011404-52-748" style={{ fontSize: '15px', position: 'absolute', top: '14%', left: '28%', textDecoration: 'none', color: 'whitesmoke' }}>
              Toll free : (011) 404-52-748
            </a>

            <Typography sx={{ position: 'absolute', top: '18%', left: '28%' }} >
              <a href="tel:+918826081545" style={{ textDecoration: 'none', color: 'whitesmoke' }}>
                Phone No : (+91) 8826081545
              </a>
            </Typography>
          </Box>
        </Box>
      )}
      {isDesktop ? (
        <Box>
          <Box sx={{ xs: '500px', position: 'absolute', top: '16%', ml: '9%' }}>
            <h1 style={{ color: 'white', fontSize: '34px' }}>
              India's #1 Cloud-based Inbuilt AI
              Business <br /> Accounting Software
              For Retail & GST Billing.
            </h1>
            <h2 style={{ xs: '500px', fontSize: '24px', color: 'white' }}>
              अब GST Filling भी और Accounting भी,
              Online भी और Offline भी{' '}
            </h2>
            {isDesktop && <LazyLoadImage src="assets/images/offer/1234.webp" alt="err" style={{ width: '500px' }} />}
          </Box>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card
              sx={{
                ml: '70%',
                width: '23%',
                mt: '1rem',
                mb: '3%',
                top: '1%',
                mr: '25%',
                textAlign: 'center',
                position: 'absolute',
              }}
            >
              <LazyLoadImage src="assets/images/offer/question1.jpg" alt="err" style={{ width: '350px',height:'20rem', }} />
              <h2 style={{ padding: '9px', marginTop: '5%', color: 'blue' }}>REQUEST FOR A Question</h2>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message || errorMessage}</Alert>}
              <RHFTextField
                sx={{ width: '70%', height: '20%', ml: '5%' }}
                name="customerName"
                label="Enter Your Name*"
              />
              <RHFTextField sx={{ width: '70%', ml: '5%', mt: '3%' }} name="quoteEmail" label="E-mail Address*" />
              <RHFTextField
                sx={{ width: '70%', height: '20%', ml: '5%', mt: '3%' }}
                name="quoteMobile"
                label="Enter Your Contact Number*"
                type="Number"
              />
              <RHFTextField
                sx={{ width: '70%', height: '20%', ml: '5%', mt: '3%' }}
                name="quoteMessage"
                label="Enter A Question*"
              />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{
                  ml: '1%', mt: '6%', background: 'blue', width: '40%', borderRadius: '4px', mb: '5%'
                  , ':hover': { background: 'blue', color: 'white', scale: '1.2' },
                }}
              >
                Send Question
              </LoadingButton>
            </Card>
          </FormProvider>
        </Box>
      ) : (
        <Box sx={{ display: 'grid', flexWrap: 'wrap' }}>
          <Box sx={{ position: 'absolute', top: '22%', ml: '13%' }}>
            <h2 style={{ color: '#FFFFF8', fontSize: '20px', textAlign: 'center' }}>
              India's #1 Cloud-based <br />
              Inbuilt AI Accounting Software <br />
              For Retail & GST Billing
            </h2>
            <h1 style={{ fontSize: '17px', color: 'white', textAlign: 'center' }}>
              अब GST Filling भी और Accounting भी, <br />
              Online भी और Offline भी
            </h1>
            {isDesktop && <LazyLoadImage src="assets/images/offer/1234.webp" alt="err" style={{ width: '460px' }} />}
          </Box>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card
              sx={{
                ml: '5%',
                width: '90%',
                textAlign: 'center',
                mb: '3%',
                position: 'absolute',
                top: '50%',
              }}
            >
              <LazyLoadImage src="assets/images/offer/question1.jpg" alt="err" style={{ width: '400px', display:'block', alignItems:'center',justifyContent:'center', }} />
              <h2 style={{ padding: '9px', color: 'blue' }}>REQUEST FOR A QUOTE</h2>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message || errorMessage}</Alert>}
              <RHFTextField
                sx={{ width: '70%', height: '20%', ml: '5%' }}
                name="customerName"
                label="Enter Your Name*"
              />
              <RHFTextField sx={{ width: '70%', ml: '5%', mt: '3%' }} name="quoteEmail" label="E-mail Address*" />
              <RHFTextField
                sx={{ width: '70%', height: '20%', ml: '5%', mt: '3%' }}
                name="quoteMobile"
                label="Enter Your Contact Number*"
              />
              <RHFTextField
                sx={{ width: '70%', height: '20%', ml: '5%', mt: '3%' }}
                name="quoteMessage"
                label="Enter A Quote*"
              />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ ml: '1%', mt: '6%', background: '#00A4E5', width: '40%', borderRadius: '4px', mb: '5%' }}
              >
                Send Quote
              </LoadingButton>
            </Card>
          </FormProvider>
        </Box>
      )}

    

     
      {isDesktop ? (
        <Box
          sx={{
            width: '75%',
            ml: '15%',
            mt: '7%',
            border: '1px solid blue',
            display: 'flex',
          }}
        >
          <LazyLoadImage
            src="assets/images/offer/mobile_1.webp"
            alt="err"
            style={{ marginLeft: '4%', marginTop: '1%' }}
          />
          <h1 style={{ color: 'blue', marginLeft: '10%', marginTop: '1.5%' }}>
            BUSINESS & ACCOUNTING <br />
            <span style={{ color: 'blue' }}>ON THE GO</span>
          </h1>

          <Typography sx={{ ml: '8%', mt: '1%' }}>
            {' '}
            <h2 style={{ color: 'blue' }}>All new mobile app</h2>{' '}
            <span style={{ marginLeft: '24%' }}>Download Now</span>
            <Box sx={{ display: 'flex' }}>
              {/* <a href=""> */}
              <LazyLoadImage
                src="assets/images/offer/Mobile/apple.svg"
                alt="err"
                style={{ width: '120px', marginTop: '2%' }}
              />
              {/* </a> */}
              {/* <a href=""> */}
              <LazyLoadImage
                src="assets/images/offer/Mobile/google.svg"
                alt="err"
                style={{ width: '120px', marginTop: '2%', marginLeft: '4%' }}
              />
              {/* </a> */}
            </Box>
          </Typography>
        </Box>
      ) : (

        <Box
          sx={{
            width: '75%',
            ml: '12%',
            mt: '20%',
            border: '1px solid blue',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LazyLoadImage
            src="assets/images/offer/mobile_1.webp"
            alt="err"
            style={{ marginLeft: '4%', marginTop: '1%' }}
          />
          <h1 style={{ color: '#10528B', marginLeft: '10%', marginTop: '1.5%' }}>
            BUSINESS & ACCOUNTING <br />
            <span style={{ color: 'blue' }}>ON THE GO</span>
          </h1>

          <Typography sx={{ ml: '8%', mt: '1%' }}>
            {' '}
            <h2 style={{ color: '#10528B' }}>All new mobile app</h2>{' '}
            <span style={{ marginLeft: '24%' }} > Download Now</span>
            <Box sx={{ display: 'flex' }}>
              <a href="">
                <LazyLoadImage
                  src="assets/images/offer/Mobile/apple.svg"
                  alt="err"
                  style={{ width: '120px', marginTop: '2%' }}
                />
              </a>
              <a href="">
                <LazyLoadImage
                  src="assets/images/offer/Mobile/google.svg"
                  alt="err"
                  style={{ width: '120px', marginTop: '2%', marginLeft: '4%' }}
                />
              </a>
            </Box>
          </Typography>
        </Box>
      )}
      {isDesktop ? (
        <Box
          sx={{
            mt: '3%',
            width: '75%',
            ml: '15%',
            display: 'grid',
            columnGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(5, 1fr)' },
          }}
        >
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/gst.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '30%', marginTop: '10%' }}
            />
            All GST Solutions
            <br />
            Available
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', ml: '4%', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/sku.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '30%', marginTop: '10%' }}
            />
            218000+ preconfigured <br />
            SKUs
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', ml: '4%', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/approval system.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '30%', marginTop: '10%' }}
            />
            Approval System & <br />
            User Based Access
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', ml: '4%', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/multi location.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '30%', marginTop: '10%' }}
            />
            Multi Location & <br />
            Multi Currency
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', ml: '4%', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/voice.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '30%', marginTop: '10%' }}
            />
            Operate with <br />
            Voice
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            mt: '70%',
            width: '75%',
            ml: '12%',
            display: 'grid',
            alignItems: 'center',
            columnGap: 2,
            rowGap: 2,
          }}
        >
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/gst.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '35%', marginTop: '10%' }}
            />
            All GST Solutions
            <br />
            Available
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/sku.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '35%', marginTop: '10%' }}
            />
            218000+ preconfigured <br />
            SKUs
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/approval system.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '35%', marginTop: '10%' }}
            />
            Approval System & <br />
            User Based Access
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/multi location.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '35%', marginTop: '10%' }}
            />
            Multi Location & <br />
            Multi Currency
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/voice.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '35%', marginTop: '10%' }}
            />
            Operate with <br />
            Voice
          </Card>
        </Box>
      )}
      {isDesktop ? (
        <Box
          sx={{
            background: 'blue',
            width: '75%',
            textAlign: 'center',
            ml: '15%',
            mt: '2%',
            mb: '2%',
            color: 'white',
          }}
        >
          <h1>Seamless Integrations With</h1>
        </Box>
      ) : (
        <Box
          sx={{
            background: 'blue',
            width: '75%',
            textAlign: 'center',
            fontSize: '10px',
            ml: '11%',
            mt: '2%',
            mb: '2%',
            color: 'white',
          }}
        >
          <h1>Seamless Integrations With</h1>
        </Box>

      )}

      {isDesktop ? (
        <Box
          sx={{
            mt: '3%',
            width: '75%',
            ml: '15%',
            display: 'grid',
            columnGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(4, 1fr)' },
          }}
        >
          <Card sx={{ width: '100%', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/amazon.svg"
              alt="err"
              style={{ width: '180px', marginLeft: '15%', marginTop: '4%', marginBottom: '7%' }}
            />
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', ml: '4%', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/gstin.jpg"
              alt="err"
              style={{ width: '100px', marginLeft: '27%', marginTop: '3%', marginBottom: '7%' }}
            />
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', ml: '4%', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/hdfc.webp"
              alt="err"
              style={{ width: '200px', marginLeft: '11%', marginTop: '10%', marginBottom: '7%' }}
            />
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', ml: '4%', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/paytm.svg"
              alt="err"
              style={{ width: '180px', marginLeft: '15%', marginTop: '3%', marginBottom: '10%' }}
            />
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            mt: '3%',
            width: '75%',
            ml: '11.5%',
            display: 'grid',
            alignItems: 'center',
            rowGap: 2,
          }}
        >
          <Card sx={{ width: '100%', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/amazon.svg"
              alt="err"
              style={{ width: '180px', marginLeft: '15%', marginTop: '4%', marginBottom: '7%' }}
            />
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/gstin.webp"
              alt="err"
              style={{ width: '100px', marginLeft: '27%', marginTop: '3%', marginBottom: '7%' }}
            />
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/hdfc.webp"
              alt="err"
              style={{ width: '200px', marginLeft: '11%', marginTop: '10%', marginBottom: '7%' }}
            />
          </Card>
          <Card sx={{ width: '100%', textAlign: 'center', color: 'blue', border: '1px solid blue' }}>
            <LazyLoadImage
              src="assets/images/offer/integrations/paytm.svg"
              alt="err"
              style={{ width: '180px', marginLeft: '15%', marginTop: '3%', marginBottom: '10%' }}
            />
          </Card>
        </Box>
      )}
      {isDesktop ? (
        <Box
          sx={{
            background: 'blue',
            width: '75%',
            textAlign: 'center',
            ml: '15%',
            mt: '2%',
            mb: '2%',
            color: 'white',
          }}
        >
          <h1>Modules</h1>
        </Box>
      ) : (
        <Box
          sx={{
            background: 'blue',
            width: '75%',
            textAlign: 'center',
            ml: '12%',
            fontSize: '10px',
            mt: '2%',
            mb: '2%',
            color: 'white',
          }}
        >
          <h1>Modules</h1>
        </Box>
      )}

      <Box
        sx={{
          mt: '3%',
          width: '75%',
          ml: '17%',
          display: 'grid',
          columnGap: 2,
          gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(5, 1fr)' },
        }}
      >
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/sales.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          Sales/Billing
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/purchase.svg"
            alt="err"
            style={{ width: '100px', marginTop: '3%', marginLeft: '10%', marginBottom: '10%' }}
          />
          Purchase{' '}
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/production.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          Production{' '}
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/inventory.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          Inventory{' '}
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/pos.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          POS/Retail
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%', mt: '10%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/jobwork.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          JobWork{' '}
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%', mt: '10%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/finance.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          Finance{' '}
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%', mt: '10%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/hr.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          HR/Payroll{' '}
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%', mt: '10%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/asset.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          Asset Mgt.{' '}
        </Box>
        <Box sx={{ width: '50%', textAlign: 'center', color: 'blue', ml: '4%', mt: '10%' }}>
          <LazyLoadImage
            src="assets/images/offer/modules/crm.svg"
            alt="err"
            style={{ width: '100px', marginLeft: '10%', marginTop: '3%', marginBottom: '10%' }}
          />
          CRM{' '}
        </Box>
      </Box>
      {isDesktop && (
        <Box
          sx={{
            background: 'blue',
            width: '75%',
            textAlign: 'center',
            ml: '15%',
            mt: '2%',
            mb: '2%',
            color: 'white',
          }}
        >
          <h1>Features</h1>
        </Box>
      )}

      {isDesktop && (
        <Box
          sx={{
            width: '75%',
            ml: '15%',
            display: 'grid',
            columnGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              textAlign: 'center',
              border: '2px solid blue',
              borderRadius: '12px',
              mt: '19%',
            }}
          >
            <LazyLoadImage
              src="assets/images/offer/features/approvals.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '150px', marginTop: '-50px' }}
            />
            <Typography
              sx={{
                mt: '6%',
                borderTop: '2px solid blue',
                width: '80%',
                ml: '10%',
                color: '#1C5E8C',
                fontSize: '14px',
              }}
            >
              Maintain an approval hierarchy for <br /> all documents based on value. Provide approvals with a maker &{' '}
              checker system to keep a close watch on business while on the go.
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              width: '100%',
              textAlign: 'center',
              border: '2px solid blue',
              borderRadius: '12px',
              mt: '19%',
            }}
          >
            <LazyLoadImage
              src="assets/images/offer/features/user access.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '150px', marginTop: '-50px' }}
            />
            <Typography
              sx={{
                borderTop: '2px solid blue',
                width: '80%',
                ml: '10%',
                color: '#1C5E8C',
                fontSize: '14px',
              }}
            >
              User based rights and access management for different companies, modules, dashboards and data. Two-factor
              authentication through OTP.
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              width: '100%',
              textAlign: 'center',
              border: '2px solid blue',
              borderRadius: '12px',
              mt: '19%',
            }}
          >
            <LazyLoadImage
              src="assets/images/offer/features/dashboards.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '150px', marginTop: '-50px' }}
            />
            <Typography
              sx={{
                borderTop: '2px solid blue',
                width: '80%',
                ml: '10%',
                color: '#1C5E8C',
                fontSize: '14px',
              }}
            >
              Intuitive real-time dashboards & reports for a bird' eye view of your Co's performance. Analyze reports to
              boost sales, optimize inventory, purchases, & control expenses.
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              width: '100%',
              textAlign: 'center',
              border: '2px solid blue',
              borderRadius: '12px',
              mt: '19%',
            }}
          >
            <LazyLoadImage
              src="assets/images/offer/features/multi-location.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '150px', marginTop: '-50px' }}
            />
            <Typography
              sx={{
                mt: '7%',

                borderTop: '2px solid blue',
                width: '80%',
                ml: '10%',
                color: '#1C5E8C',
                fontSize: '14px',
              }}
            >
              Operate multiple locations in a single company and view location wise or consolidated reports. Restrict
              users to visibility of a particular location data only.
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              width: '100%',
              textAlign: 'center',
              border: '2px solid blue',
              borderRadius: '12px',
              mt: '19%',
            }}
          >
            <LazyLoadImage
              src="assets/images/offer/features/multicurrency.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '150px', marginTop: '-50px' }}
            />
            <Typography
              sx={{
                mt: '7%',

                borderTop: '2px solid blue',
                width: '80%',
                ml: '10%',
                color: '#1C5E8C',
                fontSize: '14px',
              }}
            >
              Transact internationally with user-defined or automatic conversion rates for all documents. Maintain a
              global presence & view currency gains/losses in real-time
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              width: '100%',
              textAlign: 'center',
              border: '2px solid blue',
              borderRadius: '12px',
              mt: '19%',
            }}
          >
            <LazyLoadImage
              src="assets/images/offer/features/emailsms.svg"
              alt="err"
              style={{ width: '80px', marginLeft: '150px', marginTop: '-50px' }}
            />
            <Typography
              sx={{
                mt: '1%',
                borderTop: '2px solid blue',
                width: '80%',
                ml: '10%',
                color: '#1C5E8C',
                fontSize: '14px',
              }}
            >
              Configure custom email alerts for every transaction. Automatically send invoice details to your customers
              through emails.
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ textAlign: 'center', mt: '3%', padding: '5px', width: '100%' }}>
        <h1 style={{ marginTop: '1%', fontSize: '40px' }}>PRICING</h1>

        <Stack
          alignItems={isDesktop ? 'flex-end' : 'center'}
          sx={{ marginRight: isDesktop && '15%', textAlign: 'center' }}
        >
          <Tabs value={state} sx={{}}>
            <Tab sx={{ color: 'blue',fontSize:'20px' }}
              value={0}
              onClick={() => setState(0)}
              // sx={{ 'margin-right': '10px !important' }}
              label="Monthly"
              wrapped={abc}
            >
              Tab A
            </Tab>
            <Tab sx={{ color: 'blue' ,fontSize:'20px' }} value={1} onClick={() => setState(1)} label="Yearly">
              Tab B
            </Tab>
          </Tabs>
        </Stack>

        <Typography
          variant="caption"
          align="right"
          sx={{ color: 'black', display: 'block', marginRight: isDesktop ? '195px' : '120px', mt: 2 }}
        >
          * Plus applicable taxes
        </Typography>
       
        {isDesktop ? (
          <Box
            sx={{
              display: 'flex',
              mt: '4%',
              ml: '',
              mb: '3%',
              width: '%',
              columnGap: 4,
            }}
          >
             <Card sx={{ width: '60%',minHeight:250, ml: '3%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white', padding: '3%',minHeight:250 }}>
                <h1 style={{ padding: '3%' }}>Basic</h1>
                <p>
                  <span style={{ fontSize: '33px' }}> {state === 0 ? '₹299' : '₹3388'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: 'white', color: 'black', lineHeight: 2, textAlign: 'center',minHeight:250 }}
                >
                  <ul style={{   listStyle: 'none' }}>
                    <li>
                      Service Only
                    </li>
                    <li>All Feature Of Service</li> 
                  </ul>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: 'blue', scale: '1.2' },
                        mb: '%',
                        mt: '20%',
                      }}
                      onClick={() => handleClick(state, 1)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
            <Card sx={{ width: '60%', minHeight:250, ml: '3%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white', padding: '3%',minHeight:250, }}>
                <h1 style={{ padding: '3%' }}>Standard</h1>
                <p>
                  <span style={{ fontSize: '33px' }}> {state === 0 ? '₹499' : '₹5688'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', minHeight:250, background: 'white', color: 'black', lineHeight: 2, textAlign: 'center' }}
                >
                  <ul style={{  listStyle: 'none' }}>
                    <li>Service + Inventory</li>
                    <li>All Feature Of Service </li>
                    <li>All Feature Of Inventory</li>
                  </ul>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: 'blue', scale: '1.2' },
                        mb: '5%',
                        mt: '6%',
                      }}
                      onClick={() => handleClick(state, 1)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
            <Card sx={{ width: '60%',minHeight:250, ml: '3%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white',minHeight:250, padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Professional</h1>
                <p>
                  <span style={{ fontSize: '33px' }}> {state === 0 ? '₹599' : '₹6999'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: 'white',minHeight:250, color: 'black', lineHeight: 2, textAlign: 'center' }}
                >
                  <ul style={{ listStyle: 'none' }}>
                    <li>Service + GST</li>
                    <li>All Feature Of Service </li>
                    <li>All Feature Of GST Filing with Whatsapp </li>
                  </ul>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: 'blue', scale: '1.2' },
                        mb: '5%',
                        mt: '6%',
                      }}
                      onClick={() => handleClick(state, 1)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
            <Card sx={{ width: '70%', ml: '3%', borderRadius: 'none', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white', padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Professional Pro </h1>
                
                <p>
                  <span style={{ fontSize: '33px' }}>{state === 0 ? '₹999' : '₹10999'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: 'white', color: 'black', lineHeight: 2.4, textAlign: 'center' }}
                >
                  <ul style={{  listStyle: 'none' }}>
                    <li>Service + Inventory + GST</li>
                    <li>All Feature Of Service</li>
                    <li>All Feature Of Inventory</li>
                    <li> All Feature Of GST Filing with Whatsapp</li>
              
                  
                  </ul>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        color: 'white',
                        width: '150px',
                        ':hover': { background: 'blue', color: 'white', scale: '1.2' },
                        mb: '5%',
                        mt: '5%',
                      }}
                      onClick={() => handleClick(state, 3)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
            {/* <Card sx={{ width: '20%', ml: '3%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: '#EE3147', color: 'white', padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Premium</h1>
                <p>
                  ₹ <span style={{ fontSize: '33px', marginBottom: '%' }}>{state === 0 ? 1599 : 12600}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: '#FEE7E1', color: 'black', lineHeight: 2.2, textAlign: 'left' }}
                >
                  <ul style={{ marginLeft: '8%', listStyle: 'none' }}>
                    <li>Invite 10 users</li>
                    <li>3 GSTINs</li>
                    <li style={{ lineHeight: 1.5 }}>
                      <strong>
                        Includes everything in
                        <span style={{ color: '#6B73FF', fontSize: '18px' }}> Professional +</span>
                      </strong>
                    </li>
                    {read ? (
                      <>
                        <li>Vendor Portal</li>
                        <li>Multi-Transaction Number Series</li>
                        <li>Budgeting</li>
                        <li>Print Cheques</li>
                        <li>Custom Domain</li>
                        <li>Custom Status</li>
                        <li>Custom Buttons and Links</li>
                        <li>Validation Rules</li>
                        <li>Related Lists</li>
                        <li>WebTabs</li>
                        <li>
                          Workflow Rules (Up to 200){' '}
                          <Tooltip>
                            <IconButton>
                              {' '}
                              <Iconify icon="ion:information-circle-outline" width="18px" height="18px" />
                            </IconButton>
                          </Tooltip>
                        </li>
                        <li style={{ lineHeight: '2' }}>
                          StartupKhata Payroll (20 employees){' '}
                          <Tooltip>
                            <IconButton>
                              {' '}
                              <Iconify icon="ion:information-circle-outline" width="18px" height="18px" />
                            </IconButton>
                          </Tooltip>
                        </li>
                        <li>Custom Reports</li>
                        <li>10 Custom Modules</li>
                        <li>
                          <strong>Integrations</strong>
                        </li>
                        <li>Twilio</li>

                        <li>
                          <strong>Support</strong>
                        </li>
                        <li>Email, Voice, Chat</li>
                      </>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => setread(!read)}
                      style={{
                        ':hover': { background: 'none' },
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        marginLeft: '35%',
                        marginTop: '8%',
                      }}
                    >
                      {read ? (
                        <Iconify
                          icon="ic:outline-keyboard-double-arrow-up"
                          color="#EE3147"
                          width="30px"
                          height="30px"
                        />
                      ) : (
                        <Iconify
                          icon="ic:sharp-keyboard-double-arrow-down"
                          color="#EE3147"
                          width="30px"
                          height="30px"
                        />
                      )}
                    </button>
                  </ul>
                  <div style={{ marginTop: '5%', textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: '#EE3147',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: '#EE3147', color: 'white', scale: '1.2' },
                        mb: '5%',
                      }}
                      onClick={() => handleClick(state, 5)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card> */}
          </Box>
        ) : (
          <Box
            sx={{
              mt: '0%',
              mb: '3%',
              width: '100%',
            }}
          >
            <Card sx={{ width: '65%', ml: '18%', mt: '5%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white', padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Basic</h1>
                <p>
                  <span style={{ fontSize: '33px' }}> {state === 0 ? '₹299' : '₹3388'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: 'white', color: 'black', lineHeight: 2, textAlign: 'left' }}
                >
                  <ul style={{ marginLeft: '8%', listStyle: 'none' }}>
                    <li>Service Only</li>
                    <li>All Feature Of Service</li>
                  </ul>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: 'blue', scale: '1.2' },
                        mb: '5%',
                        mt: '6%',
                      }}
                      onClick={() => handleClick(state, 1)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
            <Card sx={{ width: '65%', ml: '18%', mt: '5%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white', padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Standard</h1>
                <p>
                  <span style={{ fontSize: '33px' }}> {state === 0 ? '₹499' : '₹5688'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: 'white', color: 'black', lineHeight: 2, textAlign: 'left' }}
                >
                  <ul style={{ marginLeft: '8%', listStyle: 'none' }}>
                    <li>Service + Inventory</li>
                    <li>All Feature Of Service</li>
                    <li>All Feature Of Inventory</li>
                  </ul>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: 'blue', scale: '1.2' },
                        mb: '5%',
                        mt: '6%',
                      }}
                      onClick={() => handleClick(state, 1)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
            <Card sx={{ width: '65%', ml: '18%', mt: '5%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white', padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Professional</h1>
                <p>
                  <span style={{ fontSize: '33px' }}> {state === 0 ? '₹599' : '₹6999'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: 'white', color: 'black', lineHeight: 2, textAlign: 'left' }}
                >
                  <ul style={{ marginLeft: '8%', listStyle: 'none' }}>
                    <li>Service + GST</li>
                    <li>All Feature Of Service</li>
                    <li>All Feature Of GST Filing with Whatsapp</li>
                  </ul>

                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: 'blue', scale: '1.2' },
                        mb: '5%',
                        mt: '6%',
                      }}
                      onClick={() => handleClick(state, 1)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
          
            <Card sx={{ width: '65%', ml: '18%', mt: '5%', borderRadius: 'none', height: '' }}>
              <Box sx={{ background: 'blue', color: 'white', padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Professional Pro</h1>
                <p>
                   <span style={{ fontSize: '33px' }}>{state === 0 ? '₹999' : '₹10999'}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: 'white', color: 'black', lineHeight: 2.4, textAlign: 'left' }}
                >
                  <ul style={{ marginLeft: '8%', listStyle: 'none' }}>
                    <li>Service + Inventory + GST</li>
                    <li>All Feature Of Service</li>
                    <li>All Feature Of Inventory</li>
                    <li >All Feature Of GST Filing with Whatsapp</li>
                   
                      </ul>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: 'blue',
                        color: 'white',
                        width: '150px',
                        ':hover': { background: 'blue', color: 'white', scale: '1.2' },
                        mb: '5%',
                        mt: '5%',
                      }}
                      onClick={() => handleClick(state, 3)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card>
            {/* <Card sx={{ width: '65%', ml: '18%', mt: '5%', borderRadius: 'none', textAlign: 'center', height: '' }}>
              <Box sx={{ background: '#EE3147', color: 'white', padding: '3%' }}>
                <h1 style={{ padding: '3%' }}>Premium</h1>
                <p>
                  ₹ <span style={{ fontSize: '33px', marginBottom: '%' }}>{state === 0 ? 1599 : 12600}</span>
                </p>

                <div
                  style={{ marginTop: '3%', background: '#FEE7E1', color: 'black', lineHeight: 2.2, textAlign: 'left' }}
                >
                  <ul style={{ marginLeft: '8%', listStyle: 'none' }}>
                    <li>Invite 10 users</li>
                    <li>3 GSTINs</li>
                    <li style={{ lineHeight: 1.5 }}>
                      <strong>
                        Includes everything in
                        <span style={{ color: '#6B73FF', fontSize: '18px' }}> Professional +</span>
                      </strong>
                    </li>
                    {read ? (
                      <>
                        <li>Vendor Portal</li>
                        <li>Multi-Transaction Number Series</li>
                        <li>Budgeting</li>
                        <li>Print Cheques</li>
                        <li>Custom Domain</li>
                        <li>Custom Status</li>
                        <li>Custom Buttons and Links</li>
                        <li>Validation Rules</li>
                        <li>Related Lists</li>
                        <li>WebTabs</li>
                        <li>
                          Workflow Rules (Up to 200){' '}
                          <Tooltip>
                            <IconButton>
                              {' '}
                              <Iconify icon="ion:information-circle-outline" width="18px" height="18px" />
                            </IconButton>
                          </Tooltip>
                        </li>
                        <li style={{ lineHeight: '2' }}>
                          StartupKhata Payroll (20 employees){' '}
                          <Tooltip>
                            <IconButton>
                              {' '}
                              <Iconify icon="ion:information-circle-outline" width="18px" height="18px" />
                            </IconButton>
                          </Tooltip>
                        </li>
                        <li>Custom Reports</li>
                        <li>10 Custom Modules</li>
                        <li>
                          <strong>Integrations</strong>
                        </li>
                        <li>Twilio</li>

                        <li>
                          <strong>Support</strong>
                        </li>
                        <li>Email, Voice, Chat</li>
                      </>
                    ) : null}
                    <button
                      type="button"
                      onClick={() => setread(!read)}
                      style={{
                        ':hover': { background: 'none' },
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        marginLeft: '35%',
                        marginTop: '8%',
                      }}
                    >
                      {read ? (
                        <Iconify
                          icon="ic:outline-keyboard-double-arrow-up"
                          color="#EE3147"
                          width="30px"
                          height="30px"
                        />
                      ) : (
                        <Iconify
                          icon="ic:sharp-keyboard-double-arrow-down"
                          color="#EE3147"
                          width="30px"
                          height="30px"
                        />
                      )}
                    </button>
                  </ul>
                  <div style={{ marginTop: '5%', textAlign: 'center' }}>
                    <Button
                      sx={{
                        background: '#EE3147',
                        width: '150px',
                        color: 'white',
                        ':hover': { background: '#EE3147', color: 'white', scale: '1.2' },
                        mb: '5%',
                        // mt: '%',
                      }}
                      onClick={() => handleClick(state, 5)}
                    >
                      Sign up
                    </Button>
                  </div>
                </div>
              </Box>
            </Card> */}
          </Box>
        )}
      </Box>
      

      {isDesktop ? (
        <Box
          sx={{
            background: 'blue',
            width: '75%',
            textAlign: 'center',
            ml: '15%',
            mt: '2%',
            mb: '2%',
            color: 'white',
          }}
        >
          <h1>Mobile & Desktop</h1>
        </Box>
      ) : (
        <Box
          sx={{
            background: 'blue',
            width: '75%',
            textAlign: 'center',
            ml: '15%',
            fontSize: '10px',
            mt: '2%',
            mb: '2%',
            color: 'white',
          }}
        >
          <h1>Mobile & Desktop</h1>
        </Box>
      )}

      <Box
        sx={{
          width: '75%',
          ml: '15%',
          display: 'grid',
          columnGap: 2,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
        }}
      >
        <Box sx={{ width: '100%' }}>
          {isDesktop ? (
            <h1 style={{ color: 'blue', fontSize: '179%' }}>
              Handle B2B requirements of your business from your mobile phone
            </h1>
          ) : (
            <h1 style={{ color: 'blue', fontSize: '150%', textAlign: 'center' }}>
              Handle B2B requirements of your business from your mobile phone
            </h1>
          )}
          <Box sx={{ border: '1px solid blue', fontSize: '20px', textAlign: 'center', padding: '4%', mt: '3%' }}>
            <strong style={{ color: 'blue' }}>Billing | Purchase | Inventory Management | Finance</strong>
          </Box>
          {isDesktop ? (
            <h2 style={{ marginTop: '5%', color: 'blue' }}>
              Startup Khata, one solution to all your business needs
            </h2>
          ) : (
            <h2 style={{ marginTop: '5%', color: 'blue', textAlign: 'center' }}>
              Startup Khata, one solution to all your business needs
            </h2>
          )}
        </Box>
        {isDesktop ? (
          <Box sx={{ ml: '25%' }}>
            <LazyLoadImage src="assets/images/offer/Mobile/mobile.webp" alt="err" style={{ width: '160px' }} />
            <Box sx={{ width: '150px' }}>
              {/* <a href=""> */}
              <LazyLoadImage
                src="assets/images/offer/Mobile/apple.svg"
                alt="err"
                style={{ width: '150px', marginLeft: '4%', marginTop: '2%' }}
              />
              {/* </a> */}

              {/* <a href=""> */}
              <LazyLoadImage
                src="assets/images/offer/Mobile/google.svg"
                alt="err"
                style={{ width: '150px', marginLeft: '4%', marginTop: '2%' }}
              />
              {/* </a> */}
            </Box>
          </Box>
        ) : (
          <Box sx={{ ml: '17%' }}>
            <LazyLoadImage src="assets/images/offer/Mobile/mobile.webp" alt="err" style={{ width: '160px' }} />
            <Box sx={{ width: '150px' }}>
              <a href="">
                <LazyLoadImage
                  src="assets/images/offer/Mobile/apple.svg"
                  alt="err"
                  style={{ width: '150px', marginLeft: '4%', marginTop: '2%' }}
                />
              </a>

              <a href="">
                <LazyLoadImage
                  src="assets/images/offer/Mobile/google.svg"
                  alt="err"
                  style={{ width: '150px', marginLeft: '4%', marginTop: '2%' }}
                />
              </a>
            </Box>
          </Box>
        )}

        <Box>
          <h1 style={{ fontSize: '134%', color: 'blue', textAlign: 'left' }}>
            Enjoy Dual Benefits of Startup Khata
          </h1>
          <Box sx={{ border: '1px solid blue', fontSize: '20px', textAlign: 'center', padding: '4%', mt: '3%' }}>
            <strong style={{ color: 'blue' }}>
              {' '}
              On Cloud - Access From Anywhere - 24x7 Sync Data On Your Desktop And Mobile- Re - Sync On Cloud
            </strong>
          </Box>
          <LazyLoadImage src="assets/images/offer/Mobile/laptop.webp" alt="laptop" style={{ marginTop: '5%' }} />
        </Box>
      </Box>

      {isDesktop ? (
        <Box>
        <Box sx={{ display:'flex',flexWrap: 'wrap', ml:'10%',mr:'13%',  mt:'0%', borderRadius: '15px', bgcolor: 'white', boxShadow: 3 }}>
          <br/>
          <h1 style={{position:'relative' , left:'2%'}}>
            What Is Online Accounting Software?
          </h1>
        <br/>
          <Typography sx={{ fontSize: '22px',ml:'2%' ,  mr:'2%', textAlign: 'justify' }}> Online accounting software is a powerful tool that efficiently handles bookkeeping tasks, manages financial information, and business compliances. It could be operated on web browser through any device from anywhere.
            Accounting software offers essential features like e-invoice generation, GST calculations, and business compliance, ensuring efficient financial management, informed decisions, and adherence to tax regulations. The best accounting software is cost-effective, user-friendly, and seamlessly integrates with other business systems such as payroll and HR software. GST compliant accounting software like HostBooks offer GST calculation, return filing and integrated e-invoicing software.</Typography>
          {/* <LazyLoadImage src="assets/images/offer/account.png" alt="err" style={{ position: 'absolute', left: '65%', bottom: '-50%' }} /> */}
          {/* <LazyLoadImage src="assets/images/offer/account.png" alt="err" style={{ ml:'75%'}}  /> */}
         </Box>
          <br /><br />
      <Box sx={{ ml: '7%', mr: '67%', borderRadius: '15px', bgcolor: 'white', boxShadow: 3, }}>
        <LazyLoadImage src="assets/images/offer/gst.png" alt="err" style={{ }} />
        <h2 style={{ textAlign:'center' }} >GST Compliant & Accounting</h2>

        <Typography sx={{ height: '330px',ml:'2%' ,  mr:'2%', textAlign: 'justify', fontSize: '20px',  }}>Accounting software is intended to simplify accounting for organizations of all sizes. Businesses can quickly produce invoices, track payments, and manage their finances with GST-compliant accounting software. Whether your company is small or large, GST-compliant software can help you save time and money on your accounting processes.</Typography>
      </Box>

      <Box sx={{ ml: '38%', mt: '-27rem', mr: '32%', borderRadius: '15px', bgcolor: 'white', boxShadow: 3,  }}>
        <LazyLoadImage src="assets/images/offer/inventory.png" alt="err" style={{ position:'sticky' }} />
        <h2 style={{ textAlign:'center' }} >Inventory Tracking</h2>

        <Typography sx={{ height: '330px',ml:'2%' ,  mr:'2%', textAlign: 'justify', fontSize: '20px',  }}>Software assists with inventory management by informing users of low stock, availability, products not selling, fast-moving items, and cost profit analysis. Multiple inventory reports are available to help you make informed decisions. You can check stock availability at any time with our accounting software. You can view the available items and their amounts. You can also see about in-stock quantities at the time of billing.</Typography>


      </Box>
      <Box sx={{ ml: '73%', mt: '-28rem', mr: '5%', borderRadius: '15px', bgcolor: 'white', boxShadow: 3, }}>
        <LazyLoadImage src="assets/images/offer/inventory.png" alt="err" style={{  }} />
        <h2 style={{textAlign:'center' }} >Easy Accounting</h2>

        <Typography sx={{ height: '330px',ml:'2%' ,  mr:'2%', textAlign: 'justify', fontSize: '20px', }}>Accounting software is designed with a simple, easy-to-use interface that makes it suitable for all users regardless of their accounting knowledge. It helps you make better financial decisions and learn more about your finances.</Typography>
      </Box>

        </Box>
        
      ) : (
        
        <Box
        sx={{ display:'flex',flexWrap: 'wrap', mt:'12rem' }}
      >
        
        <Box sx={{  borderRadius: '15px', bgcolor: 'white', boxShadow: 3 , }}>
          <br/>
        <h3>
            What Is Online Accounting Software?
          </h3>
          <Typography sx={{  }}> Online accounting software is a powerful tool that efficiently handles bookkeeping tasks, manages financial information, and business compliances. It could be operated on web browser through any device from anywhere.
            Accounting software offers essential features like e-invoice generation, GST calculations, and business compliance, ensuring efficient financial management, informed decisions, and adherence to tax regulations. The best accounting software is cost-effective, user-friendly, and seamlessly integrates with other business systems such as payroll and HR software. GST compliant accounting software like HostBooks offer GST calculation, return filing and integrated e-invoicing software.</Typography>
          </Box>
          <br/><br/>
          <Box sx={{  borderRadius: '15px', bgcolor: 'white', boxShadow: 3,  }}>
        <LazyLoadImage src="assets/images/offer/gst.png" alt="err" style={{  }} />
        <h2 style={{ display: { xs: 'block', md: 'block' }, }} >GST Compliant & Accounting</h2>

        <Typography sx={{  }}>Accounting software is intended to simplify accounting for organizations of all sizes. Businesses can quickly produce invoices, track payments, and manage their finances with GST-compliant accounting software. Whether your company is small or large, GST-compliant software can help you save time and money on your accounting processes.</Typography>
      </Box>
      <br/><br/>
      <Box sx={{  borderRadius: '15px', bgcolor: 'white', boxShadow: 3, }}>
        <LazyLoadImage src="assets/images/offer/inventory.png" alt="err" style={{ display: { xs: 'block', md: 'block' }, }} />
        <h2 style={{ }} >Inventory Tracking</h2>

        <Typography sx={{ }}>Software assists with inventory management by informing users of low stock, availability, products not selling, fast-moving items, and cost profit analysis. Multiple inventory reports are available to help you make informed decisions. You can check stock availability at any time with our accounting software. You can view the available items and their amounts. You can also see about in-stock quantities at the time of billing.</Typography>
      </Box>
      <br/><br/>
      <Box sx={{ borderRadius: '15px', bgcolor: 'white', boxShadow: 3, }}>
        <LazyLoadImage src="assets/images/offer/inventory.png" alt="err" style={{ }} />
        <h2 style={{ display: { xs: 'block', md: 'block' }, }} >Easy Accounting</h2>

        <Typography sx={{  }}>Accounting software is designed with a simple, easy-to-use interface that makes it suitable for all users regardless of their accounting knowledge. It helps you make better financial decisions and learn more about your finances.</Typography>
      </Box>

      </Box>
      )}
      <br/>
      <br/>

      <Box
        sx={{
          variant: 'body2',
          color: 'white',
          textAlign: 'center',
          width: '100%',
          background: 'blue',
          padding: '2%',
          mt: '3%',
          fontSize: '25px',
        }}
      >
        {'Copyright © '}
        {getCurrentYear()}
        {' All rights reserved.'}

      </Box>
    </Page>
  );
}
