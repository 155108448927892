import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  ledger: [],
  expense: [],
  invoice: [],
  nature: [],
  expenseEdit: [],
  tab: 'expense',
};

const slice = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    setTab(state, action) {
      state.tab = action.payload;
    },
    setExpenseEdit(state, action) {
      state.expenseEdit = action.payload;
    },
    setLedger(state, action) {
      state.ledger = action.payload;
    },
    setExpense(state, action) {
      state.expense = action.payload;
    },
    setInvoice(state, action) {
      state.invoice = action.payload;
    },
    setNature(state, action) {
      state.nature = action.payload;
    },
  },
});

export default slice.reducer;

export const { setLedger, setExpense, setInvoice, setNature, setExpenseEdit, setTab } = slice.actions;

export const getParty = () => async (dispatch) => {
  try {
    await axios.get('/expense/partyName').then((response) => dispatch(setLedger(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExpense = () => async (dispatch) => {
  try {
    await axios.get('/expense/expenseAll').then((response) => dispatch(setExpense(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getNature = () => async (dispatch) => {
  try {
    await axios.get('/expense/nature').then((response) => dispatch(setNature(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getInvoice = (id) => async (dispatch) => {
  try {
    await axios.get(`/expense/invoices?ledger_ID=${id}`).then((response) => dispatch(setInvoice(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};

export const getExpenseEdit = (id) => async (dispatch) => {
  try {
    await axios.get(`/expense/expense?ID=${id}`).then((response) => dispatch(setExpenseEdit(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
