import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  id: null,
  id2:null,
  shipId: null,
  billgst:null,
  isReverse: null,
  ship: null,
  consignor: null,
  invoice: null,
  shipShow: false,
  check: true,
  check2:true,
  open: false,
  supplyId: 6,
  pay: 0,
  gstNo: null,
  selectTax: 0,
  taxAdjustment: null,
  payableAmount: 0,
  shipForm: {},
  ledger: null,
  tab: 'invoice',
  email: '',
  ledgerName:'',
  ledName:'',
  ledNames:'',
  // sales setting work
  salesSetting: {},
  itemData:[],
  ledgerData:{},
  serviceData:[],
  placeSupply: '',
  shipSave: null,
  shipState: null,
  consignorState:null,
  pdf: false,
  year:'2024',
  fieldLength:60,
  run:false,
  convertSales:null,
  commercialData:null,
};

const slice = createSlice({
  name: 'salesprop',
  initialState,
  reducers: {
    setLedgerName(state, action) {
      state.ledgerName = action.payload;
    },
    setLedName(state, action) {
      state.ledName = action.payload;
    },
    setLedNames(state, action) {
      state.ledNames = action.payload;
    },
    setConvertSales(state, action){
      state.convertSales= action.payload;
    },
    setCommercialData(state, action){
      state.commercialData= action.payload;
    },
    setRun(state, action) {
      state.run = action.payload;
    },
    setbillgst(state, action) {
      state.billgst = action.payload;
    },
    setServiceData(state, action) {
      state.serviceData = action.payload;
    },
    setLedgerData(state, action) {
      state.ledgerData = action.payload;
    },
    setItemData(state, action) {
      state.itemData = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    setLedger(state, action) {
      state.ledger = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setId2(state, action) {
      state.id2 = action.payload;
    },
    setShipId(state, action) {
      state.shipId = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setShip(state, action) {
      state.ship = [action.payload];
    },
    setConsignor(state, action) {
      state.consignor = [action.payload];
    },
    
    setCheck(state, action) {
      state.check = action.payload;
    },
    setCheck2(state, action) {
      state.check2 = action.payload;
    },
    setShipNone(state, action) {
      state.shipShow = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setSupplyId(state, action) {
      state.supplyId = action.payload;
    },
    setPay(state, action) {
      state.pay = action.payload;
    },

    setInvoice(state, action) {
      state.invoice = action.payload;
    },
    setTaxAdjustment(state, action) {
      state.taxAdjustment = action.payload;
    },
    setSelectTax(state, action) {
      state.selectTax = action.payload;
    },
    setPlaceSupply(state, action) {
      state.placeSupply = action.payload;
    },
    setIsReverse(state, action) {
      state.isReverse = action.payload;
    },
    setShipSave(state, action) {
      state.shipSave = action.payload;
    },
    setShipState(state, action) {
      state.shipState = action.payload;
    },
    setConsignorState(state, action) {
      state.consignorState = action.payload;
    },
    setShipForm(state, action) {
      state.shipForm = action.payload;
    },
    // sales setting

    setSalesSetting(state, action) {
      const salesSetting = action.payload;
      state.salesSetting = salesSetting;
      // state.salesSetting = action.payload;
    },

    setPayableAmount(state, action) {
      state.payableAmount = action.payload;
    },
    setPdf(state, action) {
      state.pdf = action.payload;
    },
    setGstNo(state, action) {
      state.gstNo = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setCommercialData,
  setConvertSales,
  setLedgerName,
  setLedName,
  setLedNames,
  setServiceData,
  setLedgerData,
  setItemData,
  setRun,
  setId2,
  setEmail,
  setGstNo,
  setTab,
  setPdf,
  setId,
  setShipId,
  setData,
  setShip,
  setCheck,
  setCheck2,
  setShipNone,
  setOpen,
  setConsignor,
  setSupplyId,
  setPay,
  setInvoice,
  setTaxAdjustment,
  setSelectTax,
  setIsReverse,
  setPayableAmount,
  setPlaceSupply,
  setShipSave,
  setShipState,
  setConsignorState,
  setShipForm,
  setSalesSetting,
  setLedger,
  setbillgst
} = slice.actions;

export const getSalesSetting = () => async (dispatch) => {
  try {
    await axios.get('/sales/saleSettings').then((response) => dispatch(setSalesSetting(response.data)));
  } catch (error) {
    return console.error(error.message);
  }
  return true;
};
